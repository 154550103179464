import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface IDashboardSessionBody {
  start?: string;
  end?: string;
  country?: string;
}

export interface IDashboardRetentionBody {
  start?: string;
  end?: string;
  country?: string;
}

export interface ISessionOverview {
  period: string;
  newUsers: {
    value: number;
    percent: number;
  };
  DAU: {
    value: number;
    percent: number;
  };
  sessions: {
    value: number;
    percent: number;
  };
  playtime: {
    value: number;
    percent: number;
  };
  bindingUsers?: {
    value: number;
    percent: number;
  };
}

const sessionApi = {
  getOverview: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`session/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getDAUHourly: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`dau-hourly/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getDAU5Min: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`dau-5minutes/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getDAUDaily: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`dau-daily/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getIAP5Min: (config?: AxiosRequestConfig) => {
    let game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      game = 'daymare-zero';
    }
    if (game) return api.get(`${game}/iap-5-min`, config);
    else toastHelper.error('Please select a game first');
  },

  getIAP15Min: (config?: AxiosRequestConfig) => {
    let game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      game = 'daymare-zero';
    }
    if (game) return api.get(`${game}/iap-15-min`, config);
    else toastHelper.error('Please select a game first');
  },

  getIAPHourly: (config?: AxiosRequestConfig) => {
    let game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      game = 'daymare-zero';
    }
    if (game) return api.get(`${game}/iap-hourly`, config);
    else toastHelper.error('Please select a game first');
  },

  getIAPDaily: (config?: AxiosRequestConfig) => {
    let game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      game = 'daymare-zero';
    }
    if (game) return api.get(`${game}/iap-daily`, config);
    else toastHelper.error('Please select a game first');
  },

  getDashboardSession: (body?: IDashboardSessionBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      if (body) return api.post(`session/dashboard/${game}`, body, config);
      return api.post(`session/dashboard/${game}`, null, config);
    } else toastHelper.error('Please select a game first');
  },

  getDashboardRetention: (body?: IDashboardRetentionBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      if (body) return api.post(`retention/dashboard/${game}`, body, config);
      return api.post(`retention/dashboard/${game}`, null, config);
    } else toastHelper.error('Please select a game first');
  },

  getNRUHourly: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`nru-hourly/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getNRUDaily: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`nru-daily/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getIAPOverview : (config?: AxiosRequestConfig) => {
    let game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      game = 'daymare-zero';
    }
    if (game) {
      return api.get(`/${game}/iap-overview`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
};

export default sessionApi;
