import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface ILastAction {
  action: string,
  gui_id: string,
  world_id: string,
  wave_id: string,
  boss_id: string,
  times: number
}

const { getLastAction } = daymareZeroApi;

const ItemToCell = ({ value }: { value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <p className="text-14">{value}</p>
      </div>
    </div>
  );
};



const DaymareLastAction = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: stageData, isLoading: stageLoading } = useQuery(
    ['last-action', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country, filter.isTutSessionOnly],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getLastAction({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
          isTutSessionOnly: filter.isTutSessionOnly,
        },
      });
      return res as unknown as ILastAction[];
    },
  );

  const tableData = useMemo(() => {
    return stageData?.map((item) => {
      return [
        {
          value: item.action,
          usersRaw: null,
        },
        {
          value: item.world_id,
          usersRaw: null,
        },
        {
          value: item.wave_id,
          usersRaw: null,
        },
        {
          value: item.boss_id,
          usersRaw: null,
        },
        {
          value: item.gui_id,
          usersRaw: null,
        },
        {
          value: `${item.times}`,
          usersRaw: null,
        },
      ];
    });
  }, [stageData]);

  const isLoading = stageLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Last Action"
              headers={[
                'Action',
                'World',
                'Wave',
                'Boss ID',
                'GUI',
                'Times'
                ]}
                data={tableData.map((item) => {
                return item.map((subItem) => {
                  return <ItemToCell value={subItem.value as unknown as string} />;
                });
              })}
              rawData={stageData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareLastAction;
