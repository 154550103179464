import dayjs from 'dayjs';

import GunLevel from 'components/feature/custom-tracking/gun-and-dungeon/Level';
import MimilandAvgEmote from 'components/feature/custom-tracking/mimiland/AvgEmote';
import MimilandCatchCoCo from 'components/feature/custom-tracking/mimiland/CatchCoCo';
import MimilandDailyReward from 'components/feature/custom-tracking/mimiland/DailyReward';
import MimilandDonate from 'components/feature/custom-tracking/mimiland/Donate';
import MimilandEmoteItemClick from 'components/feature/custom-tracking/mimiland/EmoteItemClick';
import MimilandQuest from 'components/feature/custom-tracking/mimiland/Quest';
import MimilandStepSummary from 'components/feature/custom-tracking/mimiland/StepSummary';
import MimilandStory from 'components/feature/custom-tracking/mimiland/Story';
import MimilandTabImpression from 'components/feature/custom-tracking/mimiland/TabImpression';
import MimilandTeleport from 'components/feature/custom-tracking/mimiland/Teleport';
import MimilandTetEvent from 'components/feature/custom-tracking/mimiland/TetEvent';
import MimilandXMasEvent from 'components/feature/custom-tracking/mimiland/XMasEvent';
import SkyAdsDetail from 'components/feature/custom-tracking/skydancer2/SkyAdsDetail';
import SkyBoss from 'components/feature/custom-tracking/skydancer2/SkyBoss';
import SkyIAPPack from 'components/feature/custom-tracking/skydancer2/SkyIAPPack';
import SkyLastAction from 'components/feature/custom-tracking/skydancer2/SkyLastAction';
import SkyLastActionFirstLaunch from 'components/feature/custom-tracking/skydancer2/SkyLastActionFirstLaunch';
import SkyLeaderboard from 'components/feature/custom-tracking/skydancer2/SkyLeaderboard';
import SkyResource from 'components/feature/custom-tracking/skydancer2/SkyResource';
import SkyStageProgression from 'components/feature/custom-tracking/skydancer2/SkyStageProgression';
import SkyUserLeft from 'components/feature/custom-tracking/skydancer2/SkyUserLeft';
import DaymareAdsDetail from '../../components/feature/custom-tracking/daymare-zero/DaymareAdsDetail';
import DaymareIAPPackBuyingDetails from '../../components/feature/custom-tracking/daymare-zero/DaymareIAPPackBuyingDetails';
import DaymareResource from '../../components/feature/custom-tracking/daymare-zero/DaymareResource';
import DaymareBoss from '../../components/feature/custom-tracking/daymare-zero/DaymareBoss';
import DaymareStage_GroupByPeriod from '../../components/feature/custom-tracking/daymare-zero/DaymareStage_GroupByPeriod';
import DaymareStage_GroupByStage from '../../components/feature/custom-tracking/daymare-zero/DaymareStage_GroupByStage';
import DaymareLastAction from '../../components/feature/custom-tracking/daymare-zero/DaymareLastAction';
import DaymareGacha from '../../components/feature/custom-tracking/daymare-zero/DaymareGacha';
import DaymareOpenChest from '../../components/feature/custom-tracking/daymare-zero/DaymareOpenChest';
import DaymareUserProfile from '../../components/feature/custom-tracking/daymare-zero/DaymareUserProfile';
import DaymareUserEquipment from '../../components/feature/custom-tracking/daymare-zero/DaymareUserEquipment';
import DaymareIAPSuccessRawData from '../../components/feature/custom-tracking/daymare-zero/DaymareIAPSuccessRawData';
import Daymare7DayQuest from '../../components/feature/custom-tracking/daymare-zero/Daymare7DayQuest';
import DaymarePvp from '../../components/feature/custom-tracking/daymare-zero/DaymarePvp';
import DaymareTopPU from '../../components/feature/custom-tracking/daymare-zero/DaymareTopPU';
import DaymareGuiClick from '../../components/feature/custom-tracking/daymare-zero/DaymareGuiClick';
import DailyReport from '../../components/feature/reports/DailyReport';
import DailyReport1 from '../../components/feature/reports/DailyReport1';
import MonthlyReport from '../../components/feature/reports/MonthlyReport';
import DayLTV from '../../components/feature/reports/DayLTV';
import MonthLTV from '../../components/feature/reports/MonthLTV';
import CampaignReport from '../../components/feature/reports/CampaignReport';
import DaymareHunt from '../../components/feature/custom-tracking/daymare-zero/DaymareHunt';
import DaymarePlaytimeOfFeatures from '../../components/feature/custom-tracking/daymare-zero/DaymarePlaytimeOfFeatures';
import DaymareRetryToPassStage from '../../components/feature/custom-tracking/daymare-zero/DaymareRetryToPassStage';
import DaymareArcanaHeroAcquire from '../../components/feature/custom-tracking/daymare-zero/DaymareArcanaHeroAcquire';
import Minigame from '../../components/feature/custom-tracking/mimiland/Minigames';
import Resources from '../../components/feature/custom-tracking/mimiland/Resources';
import DaymareAwaken from '../../components/feature/custom-tracking/daymare-zero/DaymareAwaken';
import DaymareMergeRelic from '../../components/feature/custom-tracking/daymare-zero/DaymareMergeRelic';
import DaymareOfflineEarning from '../../components/feature/custom-tracking/daymare-zero/DaymareOfflineEarning';

export const customTrackingConst: {
  [key: string]: {
    id: string;
    name: string;
    component: React.ComponentType<any>;
    type?: 'event';
    defaultRange?: {
      start: string;
      end: string;
    };
  }[];
} = {
  SkyDancer2: [
    {
      id: 'LastActionFirstLaunch',
      name: 'Last Action Of First Launch',
      component: SkyLastActionFirstLaunch,
    },
    {
      id: 'LastAction',
      name: 'Last Action Of Left Users In First 3 Games',
      component: SkyLastAction,
    },
    {
      id: 'StageProgression',
      name: 'Stage Progression',
      component: SkyStageProgression,
    },
    {
      id: 'UserLeft24h',
      name: 'Stage Of Users Left 24h',
      component: SkyUserLeft,
    },
    {
      id: 'Resource',
      name: 'Resource Income/Expend',
      component: SkyResource,
    },
    {
      id: 'IAPPack',
      name: 'IAP Pack Detail',
      component: SkyIAPPack,
    },
    {
      id: 'AdsDetail',
      name: 'Ads Detail',
      component: SkyAdsDetail,
    },
    {
      id: 'Boss',
      name: 'Boss',
      component: SkyBoss,
    },
    {
      id: 'Leaderboard',
      name: 'Leaderboard',
      component: SkyLeaderboard,
      defaultRange: {
        start: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
      },
    },
  ],
  Mimiland: [
    {
      id: 'Minigame',
      name: 'Minigame',
      component: Minigame,
    },
    {
      id: 'Resources',
      name: 'Resources',
      component: Resources,
    },
    {
      id: 'EmoteItemClick',
      name: 'Emote Item Click',
      component: MimilandEmoteItemClick,
    },
    {
      id: 'AvgEmote',
      name: 'Average Emote',
      component: MimilandAvgEmote,
    },
    {
      id: 'TabImpression',
      name: 'Emote Tab Impression',
      component: MimilandTabImpression,
    },
    {
      id: 'Teleport',
      name: 'Teleport',
      component: MimilandTeleport,
    },
    {
      id: 'CatchCoCo',
      name: 'Catch CoCo',
      component: MimilandCatchCoCo,
    },

    {
      id: 'StepSummary',
      name: 'Chat/Distance',
      component: MimilandStepSummary,
    },

    {
      id: 'XMasEvent',
      name: 'XMas Event',
      component: MimilandXMasEvent,
      type: 'event',
    },
    {
      id: 'TetEvent',
      name: 'Tet Event',
      component: MimilandTetEvent,
      type: 'event',
    },
    {
      id: 'Donate',
      name: 'Donate',
      component: MimilandDonate,
    },
    {
      id: 'Story',
      name: 'Story',
      component: MimilandStory,
    },
    {
      id: 'DailyReward',
      name: 'Daily Reward',
      component: MimilandDailyReward,
    },
    {
      id: 'Quest',
      name: 'Quest',
      component: MimilandQuest,
    },
  ],
  GunAndDungeon: [
    {
      id: 'Level',
      name: 'Level',
      component: GunLevel,
    },
  ],
  DaymareZero: [
    {
      id: 'UserProfile',
      name: 'User Profiles',
      component: DaymareUserProfile,
      defaultRange: {
        start: '',
        end: '',
      },
    },
    {
      id: 'UserEquipment',
      name: 'User Equipments',
      component: DaymareUserEquipment,
    },
    {
      id: 'IAPSuccessRawData',
      name: 'Success IAP (Raw Data)',
      component: DaymareIAPSuccessRawData
    },
    {
      id: 'IAPPackBuyingDetails',
      name: 'IAP Pack-Buying Details',
      component: DaymareIAPPackBuyingDetails
    },
    {
      id: 'AdsDetails',
      name: 'Ads Details',
      component: DaymareAdsDetail
    },
    {
      id: 'Resources',
      name: 'Resources',
      component: DaymareResource
    },
    {
      id: 'StageByPeriod',
      name: 'Stage (Group By Date)',
      component: DaymareStage_GroupByPeriod
    },
    {
      id: 'StageByStage',
      name: 'Stage (Group By StageID)',
      component: DaymareStage_GroupByStage
    },
    {
      id: 'Boss',
      name: 'Boss',
      component: DaymareBoss
    },
    {
      id: 'LastAction',
      name: 'Last Action',
      component: DaymareLastAction
    },
    {
      id: 'Gacha',
      name: 'Gacha',
      component: DaymareGacha
    },
    {
      id: 'B7DayQuest',
      name: '7-Day Quest',
      component: Daymare7DayQuest
    },
    {
      id: 'OpenChest',
      name: 'Open Chest',
      component: DaymareOpenChest
    },
    {
      id: 'Pvp',
      name: 'PVP',
      component: DaymarePvp
    },
    {
      id: 'TopPU',
      name: 'Top PU',
      component: DaymareTopPU,
    },
    {
      id: 'GuiClick',
      name: 'GUI Click',
      component: DaymareGuiClick,
    },
    {
      id: 'Hunt',
      name: 'Hunt',
      component: DaymareHunt,
    },
    {
      id: 'AvgPlaytime',
      name: 'Avg Playtime',
      component: DaymarePlaytimeOfFeatures,
    },
    {
      id: 'RetryToPassStage',
      name: 'Retry To Pass Stage',
      component: DaymareRetryToPassStage,
    },
    {
      id: 'ArcanaHeroAcquire',
      name: 'Arcana Hero Acquire',
      component: DaymareArcanaHeroAcquire,
    },
    {
      id: 'Awaken',
      name: 'Awaken',
      component: DaymareAwaken,
    },
    {
      id: 'MergeRelic',
      name: 'Merge Relic',
      component: DaymareMergeRelic,
    },
    {
      id: 'OfflineEarning',
      name: 'Offline Earning',
      component: DaymareOfflineEarning,
    },
  ]
};

export const reportsConst: {
  [key: string]: {
    id: string;
    name: string;
    component: React.ComponentType<any>;
    type?: 'event';
    defaultRange?: {
      start: string;
      end: string;
    };
    defaultMonthRange?: {
      start: string;
      end: string;
    }
  }[];
} = {
  SkyDancer2: [
    {
      id: 'DailyReport',
      name: 'Daily Report',
      component: DailyReport,
    },
  ],
  Mimiland: [
  ],
  GunAndDungeon: [
  ],
  DaymareZero: [
    {
      id: 'DailyReport',
      name: 'Daily Report - Compare',
      component: DailyReport,
    },
    {
      id: 'DailyReport1',
      name: 'Daily Report - Raw',
      component: DailyReport1,
    },
    {
      id: 'MonthlyReport',
      name: 'Monthly Report',
      component: MonthlyReport,
    },
    {
      id: 'DayLTV',
      name: 'Day LTV',
      component: DayLTV,
      defaultRange: {
        start: dayjs().subtract(8, 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      },
    },
    {
      id: 'MonthLTV',
      name: 'Month LTV',
      component: MonthLTV,
      defaultMonthRange: {
        start: dayjs().subtract(3, 'month').format('YYYY-MM'),
        end: dayjs().subtract(1, 'month').format('YYYY-MM'),
      },
    },
    {
      id: 'CampaignReport',
      name: 'Campaign Report',
      component: CampaignReport,
    },
  ]
};