import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiCoinStack, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import mimilandApi from '../../../../config/api/bigquery/mimilandApi';
import numberHelper from 'config/helpers/numberHelper';
import { ICustomTrackingFilter } from '../../../../config/types/reports';
import { itemToCell_currencyUsers, itemToCell_rawStr } from '../../../layout/protected/Custom';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IResourceCoinIncome {
  period: string;
  daily_users: number;
  weekly_login: number;
  weekly_login_users: number;
  quest: number;
  quest_users: number;
  minigame: number;
  minigame_users: number;
  iap: number;
  iap_users: number;
  free_coin_pack: number;
  free_coin_pack_users: number;
  ad_coin_pack: number;
  ad_coin_pack_users: number;
  first_purchase: number;
  first_purchase_users: number;
}

interface IResourceCoinExpend {
  period: string;
  daily_users: number;
  minigame: number;
  minigame_users: number;
  donate: number;
  donate_users: number;
}

const { getResources } = mimilandApi;
const { convertText } = numberHelper;

const ResourceItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiCoinStack className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

const Resources = ({ filter }: Props) => {
  //if (filter.resourceType === 'coin')
    return (<CoinComponent rangeDate={filter.rangeDate} country={filter.country} isFirstLoad={filter.isFirstLoad} />);
  //else
  //  return (<></>);
};

const CoinComponent = ({ rangeDate, country, isFirstLoad }: {
  rangeDate: { start?: string; end?: string; } | undefined,
  country: string,
  isFirstLoad: boolean,
}) => {
  const { platform } = LayoutContextConsumer();

  const { data: coinIncomeData, isLoading: coinIncomeLoading } = useQuery(
    [`resource-coin-income`, platform, rangeDate?.start, rangeDate?.end, country],
    async () => {
      if (isFirstLoad === true || !rangeDate?.start || !rangeDate?.end) {
        return [];
      }
      const res = await getResources({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          country: country !== 'All' ? country : undefined,
          start: rangeDate.start,
          end: rangeDate.end,
          type: `coin_income`,
        },
      });
      return res as unknown as IResourceCoinIncome[];
    },
  );

  const { data: coinExpendData, isLoading: coinExpendLoading } = useQuery(
    [`resource-coin-expend`, platform, rangeDate?.start, rangeDate?.end, country],
    async () => {
      if (isFirstLoad === true || !rangeDate?.start || !rangeDate?.end) {
        return [];
      }
      const res = await getResources({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          country: country !== 'All' ? country : undefined,
          start: rangeDate.start,
          end: rangeDate.end,
          type: `coin_expend`,
        },
      });
      return res as unknown as IResourceCoinExpend[];
    },
  );

  const coinIncomeTableData = useMemo(() => {
    return coinIncomeData?.map((item) => {
      return [
        {
          value: item.period,
          users: null,
        },
        {
          value: item.weekly_login.toLocaleString(),
          users: `${convertText(item.weekly_login_users)} (${((item.weekly_login_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.quest.toLocaleString(),
          users: `${convertText(item.quest_users)} (${((item.quest_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.minigame.toLocaleString(),
          users: `${convertText(item.minigame_users)} (${((item.minigame_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.iap.toLocaleString(),
          users: `${convertText(item.iap_users)} (${((item.iap_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.free_coin_pack.toLocaleString(),
          users: `${convertText(item.free_coin_pack_users)} (${((item.free_coin_pack_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.ad_coin_pack.toLocaleString(),
          users: `${convertText(item.ad_coin_pack_users)} (${((item.ad_coin_pack_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.first_purchase.toLocaleString(),
          users: `${convertText(item.first_purchase_users)} (${((item.first_purchase_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [coinIncomeData]);

  const coinExpendTableData = useMemo(() => {
    return coinExpendData?.map((item) => {
      return [
        {
          value: item.period,
          users: null,
        },
        {
          value: item.minigame.toLocaleString(),
          users: `${convertText(item.minigame_users)} (${((item.minigame_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.donate.toLocaleString(),
          users: `${convertText(item.donate_users)} (${((item.donate_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [coinExpendData]);

  const isLoading = coinIncomeLoading || coinExpendLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {coinIncomeTableData && coinIncomeTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Coin Income"
              headers={[
                'Time',
                'Weekly Login',
                'Quest',
                'Minigame',
                'IAP',
                'Free Coin Pack',
                'Ad Coin Pack',
                '1st IAP Rewards'
              ]}
              data={coinIncomeTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return itemToCell_currencyUsers({ amount: subItem.value, users: subItem.users });
                  } else {
                    return itemToCell_rawStr({ value: subItem.value });
                  }
                });
              })}
              rawData={coinIncomeData}
            />
          )}
          {coinExpendTableData && coinExpendTableData.length > 0 && (
            <RawDataTable
              title="Coin Expend"
              isCenterFull
              headers={[
                'Time',
                'Minigame',
                'Donate',
              ]}
              data={coinExpendTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return itemToCell_currencyUsers({ amount: subItem.value, users: subItem.users });
                  } else {
                    return itemToCell_rawStr({ value: subItem.value });
                  }
                });
              })}
              rawData={coinExpendData}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Resources;
