import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable2 from '../../charts/RawDataTable2';
import Loading from 'components/shared/Loading';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { ICustomTrackingFilter } from '../../../../config/types/reports';
import { itemToCell_currencyUsers, itemToCell_icon, itemToCell_timeUsers } from '../../../layout/protected/Custom';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IHunt {
  period: string,
  DAU_stage10: number,
  coin_start_times: number,
  coin_start_users: number,
  scroll_start_times: number,
  scroll_start_users: number,
  crystal_start_times: number,
  crystal_start_users: number,
  coin_win_times: number,
  coin_win_users: number,
  scroll_win_times: number,
  scroll_win_users: number,
  crystal_win_times: number,
  crystal_win_users: number,
  coin_quit_times: number,
  coin_quit_users: number,
  scroll_quit_times: number,
  scroll_quit_users: number,
  crystal_quit_times: number,
  crystal_quit_users: number,
  coin_win_total: number,
  scroll_win_total: number,
  crystal_win_total: number,
  coin_win_max: number,
  scroll_win_max: number,
  crystal_win_max: number,
  gem_spent: number,
  buy_times: number,
  buy_users: number,
}

const { getHunt } = daymareZeroApi;

const DaymareHunt = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['hunt', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country, filter.level],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getHunt({
        params: {
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          platform: platform === 'unified' ? undefined : platform,
          country: filter.country !== 'All' ? filter.country : undefined,
          level: filter.level !== 'All' ? filter.level : undefined,
        },
      });
      return res as unknown as IHunt[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { icon: '', value1: item.period, value2: '' },
        { icon: '', value1: item.DAU_stage10.toLocaleString(), value2: '' },
        {
          icon: 'times',
          value1: item.coin_start_times.toLocaleString(),
          value2: `${item.coin_start_users.toLocaleString()} (${(item.coin_start_users / item.DAU_stage10).toFixed(2)}%)`
        },
        {
          icon: 'times',
          value1: item.scroll_start_times.toLocaleString(),
          value2: `${item.scroll_start_users.toLocaleString()} (${(item.scroll_start_users / item.DAU_stage10).toFixed(2) }%)`
        },
        {
          icon: 'times',
          value1: item.crystal_start_times.toLocaleString(),
          value2: `${item.crystal_start_users.toLocaleString()} (${(item.crystal_start_users / item.DAU_stage10).toFixed(2)}%)`
        },
        {
          icon: 'times',
          value1: item.coin_win_times.toLocaleString(),
          value2: `${item.coin_win_users.toLocaleString()} (${(item.coin_win_users / item.DAU_stage10).toFixed(2) }%)`
        },
        {
          icon: 'times',
          value1: item.scroll_win_times.toLocaleString(),
          value2: `${item.scroll_win_users.toLocaleString()} (${(item.scroll_win_users / item.DAU_stage10).toFixed(2) }%)`
        },
        {
          icon: 'times',
          value1: item.crystal_win_times.toLocaleString(),
          value2: `${item.crystal_win_users.toLocaleString()} (${(item.crystal_win_users / item.DAU_stage10).toFixed(2)}%)`
        },
        {
          icon: 'times',
          value1: item.coin_quit_times.toLocaleString(),
          value2: `${item.coin_quit_users.toLocaleString()} (${(item.coin_quit_users / item.DAU_stage10).toFixed(2) }%)`
        },
        {
          icon: 'times',
          value1: item.scroll_quit_times.toLocaleString(),
          value2: `${item.scroll_quit_users.toLocaleString()} (${(item.scroll_quit_users / item.DAU_stage10).toFixed(2) }%)`
        },
        {
          icon: 'times',
          value1: item.crystal_quit_times.toLocaleString(),
          value2: `${item.crystal_quit_users.toLocaleString()} (${(item.crystal_quit_users / item.DAU_stage10).toFixed(2)}%)`
        },
        { icon: 'coins', value1: item.coin_win_total.toLocaleString(), value2: '' },
        { icon: 'coins', value1: item.scroll_win_total.toLocaleString(), value2: '' },
        { icon: 'coins', value1: item.crystal_win_total.toLocaleString(), value2: '' },
        { icon: 'coins', value1: item.coin_win_max.toLocaleString(), value2: '' },
        { icon: 'coins', value1: item.scroll_win_max.toLocaleString(), value2: '' },
        { icon: 'coins', value1: item.crystal_win_max.toLocaleString(), value2: '' },
        { icon: 'coins', value1: item.coin_win_times > 0 ? Number((item.coin_win_total / item.coin_win_times).toFixed(0)).toLocaleString() : '0', value2: '' },
        { icon: 'coins', value1: item.scroll_win_times > 0 ? Number((item.scroll_win_total / item.scroll_win_times).toFixed(0)).toLocaleString() : '0', value2: '' },
        { icon: 'coins', value1: item.crystal_win_times > 0 ? Number((item.crystal_win_total / item.crystal_win_times).toFixed(0)).toLocaleString() : '0', value2: '' },
        { icon: 'times', value1: item.buy_times ? item.buy_times.toLocaleString() : '0', value2: '' },
        { icon: 'users', value1: item.buy_users ? item.buy_users.toLocaleString() : '0', value2: '' },
        { icon: 'coins', value1: item.gem_spent ? item.gem_spent.toLocaleString() : '0', value2: '' },
      ];
    });
  }, [rawData]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable2
                isCenterFull
                title="Hunt"
                headers={[
                  { header: 'Time', subHeaders: null },
                  { header: 'DAU (S.10)', subHeaders: null },
                  { header: 'Start', subHeaders: ['Coin', 'Scroll', 'Crystal'] },
                  { header: 'Win', subHeaders: ['Coin', 'Scroll', 'Crystal'] },
                  { header: 'Quit', subHeaders: ['Coin', 'Scroll', 'Crystal'] },
                  { header: 'Total', subHeaders: ['Coin', 'Scroll', 'Crystal'] },
                  { header: 'Max', subHeaders: ['Coin', 'Scroll', 'Crystal'] },
                  { header: 'Avg', subHeaders: ['Coin', 'Scroll', 'Crystal'] },
                  { header: 'Buy Ticket', subHeaders: ['Times', 'Users', 'Gem'] },
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    if (subItem.value1 !== '' && subItem.value2 !== '') {
                      if ('times' === subItem.icon)
                        return itemToCell_timeUsers({ times: subItem.value1, users: subItem.value2 });
                      else
                        return itemToCell_currencyUsers({ amount: subItem.value1, users: subItem.value2 });
                    } else {
                      return itemToCell_icon({ icon: subItem.icon, value: subItem.value1 });
                    }
                  });
                })}
                rawData={rawData?.map(item => {
                  return {
                    period: item.period,
                    DAU_stage10: item.DAU_stage10,
                    coin_start_times: item.coin_start_times,
                    coin_start_users: item.coin_start_users,
                    scroll_start_times: item.scroll_start_times,
                    scroll_start_users: item.scroll_start_users,
                    crystal_start_times: item.crystal_start_times,
                    crystal_start_users: item.crystal_start_users,
                    coin_win_times: item.coin_win_times,
                    coin_win_users: item.coin_win_users,
                    scroll_win_times: item.scroll_win_times,
                    scroll_win_users: item.scroll_win_users,
                    crystal_win_times: item.crystal_win_times,
                    crystal_win_users: item.crystal_win_users,
                    coin_quit_times: item.coin_quit_times,
                    coin_quit_users: item.coin_quit_users,
                    scroll_quit_times: item.scroll_quit_times,
                    scroll_quit_users: item.scroll_quit_users,
                    crystal_quit_times: item.crystal_quit_times,
                    crystal_quit_users: item.crystal_quit_users,
                    coin_win_total: item.coin_win_total,
                    scroll_win_total: item.scroll_win_total,
                    crystal_win_total: item.crystal_win_total,
                    coin_win_max: item.coin_win_max,
                    scroll_win_max: item.scroll_win_max,
                    crystal_win_max: item.crystal_win_max,
                    coin_win_avg: Number((item.coin_win_total / item.coin_win_times).toFixed(0)),
                    scroll_win_avg: Number((item.scroll_win_total / item.scroll_win_times).toFixed(0)),
                    crystal_win_avg: Number((item.crystal_win_total / item.crystal_win_times).toFixed(0)),
                    gem_spent: item.gem_spent,
                    buy_times: item.buy_times,
                    buy_users: item.buy_users,
                  };
                })}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default DaymareHunt;
