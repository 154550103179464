import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable2 from '../../charts/RawDataTable2';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IOfflineEarning {
  period: string,
  type_0_times: number,
  type_0_users: number,
  type_2_times: number,
  type_2_users: number,
  type_3_times: number,
  type_3_users: number,
}

const { getOfflineEarningData } = daymareZeroApi;

const DaymareOfflineEarning = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['offline-earning', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getOfflineEarningData({
        params: {
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IOfflineEarning[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.period },
        { value: item.type_0_times },
        { value: item.type_0_users },
        { value: item.type_2_times },
        { value: item.type_2_users },
        { value: item.type_3_times },
        { value: item.type_3_users },
      ];
    });
  }, [rawData]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable2
                isCenterFull
                title="Top PU"
                headers={[
                  { header: 'Time', subHeaders: null },
                  { header: 'Free', subHeaders: ['Times', 'Users'] },
                  { header: 'Ad', subHeaders: ['Times', 'Users'] },
                  { header: 'Gem', subHeaders: ['Times', 'Users'] }
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return subItem.value;
                  });
                })}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default DaymareOfflineEarning;
