import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import Pager from '../../../shared/Pager';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { appTimezoneConst, formatTimezoneOffset, toExcelFileName, writeExcelFile } from '../../../../config/constants/general';
import { ICustomTrackingFilter } from '../../../../config/types/reports';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IIAPSuccessRawData {
  time: string,
  platform: string,
  country: string,
  user_id: string,
  user_name: string,
  user_level: number,
  last_action_date: string,
  placement: string,
  package_id: string,
  currency: string,
  topup_amount: number,
  transaction_id: string,
}

interface IIAPSuccessRawData_Counter {
  total_items: number,
}

const { getSuccessIAP, countSuccessIAP } = daymareZeroApi;

const DaymareIAPSuccessRawData = ({ filter }: Props) => {
  const { currentApp, platform } = LayoutContextConsumer();
  const [successIAPPage, setSuccessIAPPage] = useState<number>(1);

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['iap-success', platform, successIAPPage, filter.rangeDate?.start, filter.rangeDate?.end, filter.country, filter.chestID, filter.userID],
    async () => {
      if (!filter || filter.isFirstLoad === true) {
        return [];
      }
      const res = await getSuccessIAP({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          country: filter.country !== 'All' ? filter.country : undefined,
          timeOffset: formatTimezoneOffset(timezone),
          chestID: filter.chestID,
          page: successIAPPage,
          pageSize: 10,
        },
        data: {
          userID: filter.userID,
        }
      });
      return res as unknown as IIAPSuccessRawData[];
    }
  );

  const { data: rawDataCounter, isLoading: rawDataCounterLoading } = useQuery(
    ['iap-success-counter', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country, filter.chestID, filter.userID],
    async () => {
      if (!filter || filter.isFirstLoad === true) {
        return [];
      }
      const res = await countSuccessIAP({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          country: filter.country !== 'All' ? filter.country : undefined,
          chestID: filter.chestID,
        },
        data: {
          userID: filter.userID,
        }
      });
      return res as unknown as IIAPSuccessRawData_Counter[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.time },
        { value: item.platform },
        { value: item.country },
        { value: item.user_id },
        { value: item.user_name },
        { value: item.user_level.toString() },
        { value: item.last_action_date },
        { value: item.placement },
        { value: item.package_id },
        { value: item.currency },
        { value: item.topup_amount.toString() },
        { value: item.transaction_id },
      ];
    });
  }, [rawData]);

  const guiFormOfCounterData = useMemo(() => {
    if (rawDataCounter !== undefined && rawDataCounter.length > 0) {
      return rawDataCounter[0].total_items;
    }
    return 1;
  }, [rawDataCounter]);

  const onDownload = async () => {
    const res = await getSuccessIAP({
      params: {
        platform: platform === 'unified' ? undefined : platform,
        start: filter.rangeDate?.start,
        end: filter.rangeDate?.end,
        country: filter.country !== 'All' ? filter.country : undefined,
        timeOffset: formatTimezoneOffset(timezone),
        chestID: filter.chestID,
      },
      data: {
        userID: filter.userID,
      }
    });
    writeExcelFile(res, toExcelFileName('Success IAP'), 'json');
  };

  const isLoading = rawDataLoading || rawDataCounterLoading;
  const timezone = (currentApp && appTimezoneConst[currentApp.id]) ? appTimezoneConst[currentApp.id] : 0;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="Success IAP"
                headers={[
                  'Time',
                  'Platform',
                  'Country',
                  'User ID',
                  'User Name',
                  'User Level',
                  'Last Action',
                  'Placement',
                  'Package ID',
                  'Topup Amount',
                  'Currency',
                  'Transaction ID',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return itemToCell_rawStr({ value: subItem.value });
                  });
                })}
                onDownload={onDownload}
              />
            )
          }
          <Pager
            page={successIAPPage}
            totalPage={Math.floor((guiFormOfCounterData + 9) / 10)}
            setPage={setSuccessIAPPage}
          />
        </div>
      )}
    </div>
  );
};

export default DaymareIAPSuccessRawData;
