import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable from '../../charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IRetryToPassStage {
  user_id: string,
  date: string,
  user_name: string,
}

const { getArcanaAcquireLog } = daymareZeroApi;

const DaymareArcanaHeroAcquire = ({ filter }: Props) => {
  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['arcana-hero-acquire', filter?.heroID],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.heroID) {
        return [];
      }
      const res = await getArcanaAcquireLog({
        params: {
          heroID: filter.heroID,
        },
      });
      return res as unknown as IRetryToPassStage[];
    },
  );

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.user_id },
        { value: item.date },
        { value: item.user_name },
      ];
    });
  }, [rawData]);

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title='Arcana Hero Acquire'
              headers={[
                'User ID',
                'Date',
                'User Name',
              ]}
              data={tableData.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_rawStr({ value: subItem.value });
                });
              })}
              rawData={rawData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareArcanaHeroAcquire;
