import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import RawDataTable2 from '../charts/RawDataTable2';
import {
  SECONDARY_COLOR,
  TABLE_COL_1,
  TABLE_COL_2,
  TABLE_COL_3,
  TABLE_COL_4,
  TABLE_COL_5,
  TABLE_COL_6,
  TABLE_COL_7,
  TABLE_COL_8,
} from '../../../config/constants/theme';
import Loading from '../../shared/Loading';
import { IReportFilter } from '../../../config/types/reports';
import { appTimezoneConst, dateStrToUnix } from '../../../config/constants/general';
import { itemToCell_icon, itemToCell_rawStr, itemToCell_rows } from '../../layout/protected/Custom';

interface Props {
  filter: IReportFilter;
}

interface ILtv {
  period: string,
  installs: number | null,
  cost: number | null,
  cpm: number | null,
  cpi: number | null,
  nru: number | null,
  ltv01_iap_revenue: number | null, ltv01_iap_users: number | null, ltv01_ad_revenue: number | null, ltv01_ad_users: number | null,
  total_ltv01: number | null, ltv01_per_user: number | null, ret_01: number | null,
  ltv03_iap_revenue: number | null, ltv03_iap_users: number | null, ltv03_ad_revenue: number | null, ltv03_ad_users: number | null,
  total_ltv03: number | null, ltv03_per_user: number | null, ret_03: number | null,
  ltv07_iap_revenue: number | null, ltv07_iap_users: number | null, ltv07_ad_revenue: number | null, ltv07_ad_users: number | null,
  total_ltv07: number | null, ltv07_per_user: number | null, ret_07: number | null,
  ltv14_iap_revenue: number | null, ltv14_iap_users: number | null, ltv14_ad_revenue: number | null, ltv14_ad_users: number | null,
  total_ltv14: number | null, ltv14_per_user: number | null, ret_14: number | null,
  ltv30_iap_revenue: number | null, ltv30_iap_users: number | null, ltv30_ad_revenue: number | null, ltv30_ad_users: number | null,
  total_ltv30: number | null, ltv30_per_user: number | null, ret_30: number | null,
  ltv60_iap_revenue: number | null, ltv60_iap_users: number | null, ltv60_ad_revenue: number | null, ltv60_ad_users: number | null,
  total_ltv60: number | null, ltv60_per_user: number | null, ret_60: number | null,
  ltv90_iap_revenue: number | null, ltv90_iap_users: number | null, ltv90_ad_revenue: number | null, ltv90_ad_users: number | null,
  total_ltv90: number | null, ltv90_per_user: number | null, ret_90: number | null,
  ltv120_iap_revenue: number | null, ltv120_iap_users: number | null, ltv120_ad_revenue: number | null, ltv120_ad_users: number | null,
  total_ltv120: number | null, ltv120_per_user: number | null, ret_120: number | null,
}

const { ltv } = daymareZeroApi;

const DayLTV = ({ filter }: Props) => {
  const { currentApp, platform } = LayoutContextConsumer();
  const timezone = (currentApp && appTimezoneConst[currentApp.id]) ? appTimezoneConst[currentApp.id] : 0;

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['ltv', filter.rangeDate?.start, filter.rangeDate?.end, filter.country, ...filter.dayLtvPeriods],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await ltv({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          startUnix: dateStrToUnix(filter.rangeDate.start, timezone, false),
          endUnix: dateStrToUnix(filter.rangeDate.end, timezone, true),
          country: filter.country !== 'All' ? filter.country : undefined,
        },
        data: {
          periods: filter.dayLtvPeriods,
        }
      });
      return res as unknown as ILtv[];
    },
  );

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      const fields = [
        [{ icon: '', value: item.period }],
        [{ icon: 'users', value: item.nru ? item.nru.toString() : '' }],
        [{ icon: '', value: item.installs ? item.installs.toString() : '' }],
        [{ icon: '', value: item.cost ? item.cost.toFixed(2).toString() : '' }],
        [{ icon: '', value: item.cpm ? item.cpm.toFixed(2).toString() : '' }],
        [{ icon: '', value: item.cost && item.nru ? (item.cost / item.nru).toFixed(2).toString() : '' }],
        [{ icon: '', value: item.cost && item.installs ? (item.cost / item.installs).toFixed(2).toString() : '' }],
      ];
      if (filter.dayLtvPeriods.includes(1)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv01_iap_revenue ? item.ltv01_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv01_iap_users ? item.ltv01_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv01_ad_revenue ? item.ltv01_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv01_ad_users ? item.ltv01_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv01 ? item.total_ltv01.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv01_per_user ? item.ltv01_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_01 ? `${item.ret_01}` : ''  },
            { icon: 'percent', value: item.ret_01 && item.nru ? `${(item.ret_01 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.dayLtvPeriods.includes(3)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv03_iap_revenue ? item.ltv03_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv03_iap_users ? item.ltv03_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv03_ad_revenue ? item.ltv03_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv03_ad_users ? item.ltv03_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv03 ? item.total_ltv03.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv03_per_user ? item.ltv03_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_03 ? `${item.ret_03}` : '',  },
            { icon: 'percent', value: item.ret_03 && item.nru ? `${(item.ret_03 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.dayLtvPeriods.includes(7)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv07_iap_revenue ? item.ltv07_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv07_iap_users ? item.ltv07_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv07_ad_revenue ? item.ltv07_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv07_ad_users ? item.ltv07_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv07 ? item.total_ltv07.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv07_per_user ? item.ltv07_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_07 ? `${item.ret_07}` : '' },
            { icon: 'percent', value: item.ret_07 && item.nru ? `${(item.ret_07 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.dayLtvPeriods.includes(14)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv14_iap_revenue ? item.ltv14_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv14_iap_users ? item.ltv14_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv14_ad_revenue ? item.ltv14_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv14_ad_users ? item.ltv14_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv14 ? item.total_ltv14.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv14_per_user ? item.ltv14_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_14 ? `${item.ret_14}` : '' },
            { icon: 'percent', value: item.ret_14 && item.nru ? `${(item.ret_14 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.dayLtvPeriods.includes(30)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv30_iap_revenue ? item.ltv30_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv30_iap_users ? item.ltv30_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv30_ad_revenue ? item.ltv30_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv30_ad_users ? item.ltv30_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv30 ? item.total_ltv30.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv30_per_user ? item.ltv30_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_30 ? `${item.ret_30}` : '' },
            { icon: 'percent', value: item.ret_30 && item.nru ? `${(item.ret_30 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.dayLtvPeriods.includes(60)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv60_iap_revenue ? item.ltv60_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv60_iap_users ? item.ltv60_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv60_ad_revenue ? item.ltv60_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv60_ad_users ? item.ltv60_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv60 ? item.total_ltv60.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv60_per_user ? item.ltv60_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_60 ? `${item.ret_60}` : '' },
            { icon: 'percent', value: item.ret_60 && item.nru ? `${(item.ret_60 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.dayLtvPeriods.includes(90)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv90_iap_revenue ? item.ltv90_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv90_iap_users ? item.ltv90_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv90_ad_revenue ? item.ltv90_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv90_ad_users ? item.ltv90_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv90 ? item.total_ltv90.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv90_per_user ? item.ltv90_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_90 ? `${item.ret_90}` : '' },
            { icon: 'percent', value: item.ret_90 && item.nru ? `${(item.ret_90 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.dayLtvPeriods.includes(120)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv120_iap_revenue ? item.ltv120_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv120_iap_users ? item.ltv120_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv120_ad_revenue ? item.ltv120_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv120_ad_users ? item.ltv120_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv120 ? item.total_ltv120.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv120_per_user ? item.ltv120_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_120 ? `${item.ret_120}` : '' },
            { icon: 'percent', value: item.ret_120 && item.nru ? `${(item.ret_120 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      return fields;
    });
  }, [filter.dayLtvPeriods, rawData]);

  const getHeaders = () => {
    const headers: { header: string, subHeaders: string[] | null }[] = [
      { header: 'Period', subHeaders: null },
      { header: 'NRU', subHeaders: null },
      { header: 'Installs', subHeaders: null },
      { header: 'Cost ($)', subHeaders: null },
      { header: 'CPM ($)', subHeaders: null },
      { header: 'CPN ($)', subHeaders: null },
      { header: 'CPI ($)', subHeaders: null },
    ];
    if (filter.dayLtvPeriods.includes(1)) {
      headers.push({ header: '1-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.dayLtvPeriods.includes(3)) {
      headers.push({ header: '3-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.dayLtvPeriods.includes(7)) {
      headers.push({ header: '7-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.dayLtvPeriods.includes(14)) {
      headers.push({ header: '14-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.dayLtvPeriods.includes(30)) {
      headers.push({ header: '30-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.dayLtvPeriods.includes(60)) {
      headers.push({ header: '60-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.dayLtvPeriods.includes(90)) {
      headers.push({ header: '90-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.dayLtvPeriods.includes(120)) {
      headers.push({ header: '120-Day', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    return headers;
  };

  const getColors = () => {
    const colors = [
      SECONDARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR,
      SECONDARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR
    ];
    if (filter.dayLtvPeriods.includes(1)) {
      colors.push(...[TABLE_COL_1]);
    }
    if (filter.dayLtvPeriods.includes(3)) {
      colors.push(...[TABLE_COL_2]);
    }
    if (filter.dayLtvPeriods.includes(7)) {
      colors.push(...[TABLE_COL_3]);
    }
    if (filter.dayLtvPeriods.includes(14)) {
      colors.push(...[TABLE_COL_4]);
    }
    if (filter.dayLtvPeriods.includes(30)) {
      colors.push(...[TABLE_COL_5]);
    }
    if (filter.dayLtvPeriods.includes(60)) {
      colors.push(...[TABLE_COL_6]);
    }
    if (filter.dayLtvPeriods.includes(90)) {
      colors.push(...[TABLE_COL_7]);
    }
    if (filter.dayLtvPeriods.includes(120)) {
      colors.push(...[TABLE_COL_8]);
    }
    return colors;
  };

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
              <RawDataTable2
                isCenterFull
                title="Day LTV"
                colors={getColors()}
                headers={getHeaders()}
                data={tableData.map((item) => {
                  return item.map((subItem) => {
                    if (subItem.length === 1) {
                      if (subItem[0].value === '') return (<></>);
                      else if (subItem[0].icon === null) return itemToCell_rawStr({ value: subItem[0].value });
                      else return itemToCell_icon({ value: subItem[0].value, icon: subItem[0].icon });
                    } else {
                      return itemToCell_rows({ rows: subItem });
                    }
                  });
                })}
                rawData={rawData?.map(item => {
                  return {
                    period: item.period,
                    nru: item.nru,
                    installs: item.installs,
                    cost: item.cost,
                    cpm: item.cpm,
                    cpn: item.cost && item.nru ? Number((item.cost / item.nru).toFixed(2)) : undefined,
                    cpi: item.cost && item.installs ? Number((item.cost / item.installs).toFixed(2)) : undefined,
                    ...filter.dayLtvPeriods.includes(1) && {
                      iap_rev_1d: item.ltv01_iap_revenue ? Number(item.ltv01_iap_revenue.toFixed(2)) : undefined,
                      iap_users_1d: item.ltv01_iap_users,
                      ad_rev_1d: item.ltv01_ad_revenue ? Number(item.ltv01_ad_revenue.toFixed(2)) : undefined,
                      ad_users_1d: item.ltv01_ad_users,
                      total_rev_1d: item.total_ltv01 ? Number(item.total_ltv01.toFixed(2)) : undefined,
                      ltv_1d: item.ltv01_per_user,
                      retention_1d: item.ret_01,
                      'retention_%_1d': item.ret_01 && item.nru ? Number((item.ret_01 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.dayLtvPeriods.includes(3) && {
                      iap_rev_3d: item.ltv03_iap_revenue ? Number(item.ltv03_iap_revenue.toFixed(2)) : undefined,
                      iap_users_3d: item.ltv03_iap_users,
                      ad_rev_3d: item.ltv03_ad_revenue ? Number(item.ltv03_ad_revenue.toFixed(2)) : undefined,
                      ad_users_3d: item.ltv03_ad_users,
                      total_rev_3d: item.total_ltv03 ? Number(item.total_ltv03.toFixed(2)) : undefined,
                      ltv_3d: item.ltv03_per_user,
                      retention_3d: item.ret_03,
                      'retention_%_3d': item.ret_03 && item.nru ? Number((item.ret_03 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.dayLtvPeriods.includes(7) && {
                      iap_rev_7d: item.ltv07_iap_revenue ? Number(item.ltv07_iap_revenue.toFixed(2)) : undefined,
                      iap_users_7d: item.ltv07_iap_users,
                      ad_rev_7d: item.ltv07_ad_revenue ? Number(item.ltv07_ad_revenue.toFixed(2)) : undefined,
                      ad_users_7d: item.ltv07_ad_users,
                      total_rev_7d: item.total_ltv07 ? Number(item.total_ltv07.toFixed(2)) : undefined,
                      ltv_7d: item.ltv07_per_user,
                      retention_7d: item.ret_07,
                      'retention_%_7d': item.ret_07 && item.nru ? Number((item.ret_07 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.dayLtvPeriods.includes(14) && {
                      iap_rev_14d: item.ltv14_iap_revenue ? Number(item.ltv14_iap_revenue.toFixed(2)) : undefined,
                      iap_users_14d: item.ltv14_iap_users,
                      ad_rev_14d: item.ltv14_ad_revenue ? Number(item.ltv14_ad_revenue.toFixed(2)) : undefined,
                      ad_users_14d: item.ltv14_ad_users,
                      total_rev_14d: item.total_ltv14 ? Number(item.total_ltv14.toFixed(2)) : undefined,
                      ltv_14d: item.ltv14_per_user,
                      retention_14d: item.ret_14,
                      'retention_%_14d': item.ret_14 && item.nru ? Number((item.ret_14 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.dayLtvPeriods.includes(30) && {
                      iap_rev_30d: item.ltv30_iap_revenue ? Number(item.ltv30_iap_revenue.toFixed(2)) : undefined,
                      iap_users_30d: item.ltv30_iap_users,
                      ad_rev_30d: item.ltv30_ad_revenue ? Number(item.ltv30_ad_revenue.toFixed(2)) : undefined,
                      ad_users_30d: item.ltv30_ad_users,
                      total_rev_30d: item.total_ltv30 ? Number(item.total_ltv30.toFixed(2)) : undefined,
                      ltv_30d: item.ltv30_per_user,
                      retention_30d: item.ret_30,
                      'retention_%_30d': item.ret_30 && item.nru ? Number((item.ret_30 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.dayLtvPeriods.includes(60) && {
                      iap_rev_60d: item.ltv60_iap_revenue ? Number(item.ltv60_iap_revenue.toFixed(2)) : undefined,
                      iap_users_60d: item.ltv60_iap_users,
                      ad_rev_60d: item.ltv60_ad_revenue ? Number(item.ltv60_ad_revenue.toFixed(2)) : undefined,
                      ad_users_60d: item.ltv60_ad_users,
                      total_rev_60d: item.total_ltv60 ? Number(item.total_ltv60.toFixed(2)) : undefined,
                      ltv_60d: item.ltv60_per_user,
                      retention_60d: item.ret_60,
                      'retention_%_60d': item.ret_60 && item.nru ? Number((item.ret_60 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.dayLtvPeriods.includes(90) && {
                      iap_rev_90d: item.ltv90_iap_revenue ? Number(item.ltv90_iap_revenue.toFixed(2)) : undefined,
                      iap_users_90d: item.ltv90_iap_users,
                      ad_rev_90d: item.ltv90_ad_revenue ? Number(item.ltv90_ad_revenue.toFixed(2)) : undefined,
                      ad_users_90d: item.ltv90_ad_users,
                      total_rev_90d: item.total_ltv90 ? Number(item.total_ltv90.toFixed(2)) : undefined,
                      ltv_90d: item.ltv90_per_user,
                      retention_90d: item.ret_90,
                      'retention_%_90d': item.ret_90 && item.nru ? Number((item.ret_90 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.dayLtvPeriods.includes(120) && {
                      iap_rev_120d: item.ltv120_iap_revenue ? Number(item.ltv120_iap_revenue.toFixed(2)) : undefined,
                      iap_users_120d: item.ltv120_iap_users,
                      ad_rev_120d: item.ltv120_ad_revenue ? Number(item.ltv120_ad_revenue.toFixed(2)) : undefined,
                      ad_users_120d: item.ltv120_ad_users,
                      total_rev_120d: item.total_ltv120 ? Number(item.total_ltv120.toFixed(2)) : undefined,
                      ltv_120d: item.ltv120_per_user,
                      retention_120d: item.ret_120,
                      'retention_%_120d': item.ret_120 && item.nru ? Number((item.ret_120 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                  };
                })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DayLTV;
