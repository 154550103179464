import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from '../../charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { itemToCell_icon, itemToCell_rawStr } from '../../../layout/protected/Custom';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IRetryToPassStage {
  world: number,
  total_times: number,
  users: number,
  avg_times: number,
}

const { getAvgRetryToPassStage } = daymareZeroApi;

const DaymareRetryToPassStage = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['stage-retry', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getAvgRetryToPassStage({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IRetryToPassStage[];
    },
  );

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { icon: '', value: item.world.toLocaleString() },
        { icon: 'times', value: item.total_times.toLocaleString() },
        { icon: 'users', value: item.users.toLocaleString() },
        { icon: 'times', value: item.avg_times.toString() },
      ];
    });
  }, [rawData]);

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Retry To Pass Stage"
              headers={[
                'Stage',
                'Times',
                'Users',
                'Avg',
              ]}
              data={tableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.icon === '')
                    return itemToCell_rawStr({ value: subItem.value });
                  else
                    return itemToCell_icon({ value: subItem.value, icon: subItem.icon });
                });
              })}
              rawData={rawData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareRetryToPassStage;
