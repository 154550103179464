import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable2 from '../../charts/RawDataTable2';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import numberHelper from '../../../../config/helpers/numberHelper';
import { itemToCell_rawStr, itemToCell_timeUsers, itemToCell_icon } from '../../../layout/protected/Custom';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IPvp {
  period: string,
  daily_users: number,
  start_times: number,
  start_users: number,
  end_times: number,
  end_users: number,
  win_times: number,
  win_users: number,
  lose_times: number,
  lose_users: number,
  claim_point_times: number,
  claim_point_users: number,
  total_point_claimed: number,
  buy_tiket_times: number,
  buy_tiket_users: number,
  total_ticket_bought: number,
  total_gem_spent: number,
}

const { getPvp } = daymareZeroApi;
const { convertText } = numberHelper;

const DaymarePvp = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['pvp', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getPvp({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IPvp[];
    },
  );

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        {
          times: item.period.toString(),
          users: null,
        },
        {
          times: item.start_times.toString(),
          users: `${convertText(item.start_users)} (${((item.start_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          times: item.end_times.toString(),
          users: `${convertText(item.end_users)} (${((item.end_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          times: item.win_times.toString(),
          users: `${convertText(item.win_users)} (${((item.win_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          times: item.lose_times.toString(),
          users: `${convertText(item.lose_users)} (${((item.lose_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          times: item.claim_point_times.toString(),
          users: `${convertText(item.claim_point_users)} (${((item.claim_point_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          times: item.total_point_claimed.toString(),
          icon: 'coins',
        },
        {
          times: item.buy_tiket_times.toString(),
          users: `${convertText(item.buy_tiket_users)} (${((item.buy_tiket_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          times: item.total_ticket_bought.toString(),
          icon: 'coins',
        },
        {
          times: item.total_gem_spent.toString(),
          icon: 'coins',
        },
      ] as {
        times: string;
        users?: string | undefined | null;
        icon?: string | undefined | null;
      }[];
    });
  }, [rawData]);

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable2
              isCenterFull
              title="PVP"
              headers={[
                { header: 'Time', subHeaders: null },
                { header: 'Start', subHeaders: null },
                { header: 'End', subHeaders: null },
                { header: 'Win', subHeaders: null },
                { header: 'Lose', subHeaders: null },
                { header: 'Claim Point', subHeaders: ['Times | Users', 'Total Point'] },
                { header: 'Buy Ticket', subHeaders: ['Times | Users', 'Ticket', 'Gem'] },
              ]}
              data={tableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return itemToCell_timeUsers({ times: subItem.times, users: subItem.users });
                  } else {
                    if (subItem.icon) {
                      return itemToCell_icon({ value: subItem.times, icon: subItem.icon });
                    } else {
                      return itemToCell_rawStr({ value: subItem.times });
                    }
                  }
                });
              })}
              rawData={rawData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymarePvp;
