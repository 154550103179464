import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable2 from '../../charts/RawDataTable2';
import Loading from 'components/shared/Loading';

import mimilandApi from '../../../../config/api/bigquery/mimilandApi';
import { ICustomTrackingFilter } from '../../../../config/types/reports';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';
import { roundFloatAndFormat } from '../../../../config/constants/general';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IMinigame {
  period: string,
  daily_users: number,
  total_duration: number,
  max_betting: number,
  min_betting: number,
  total_coin_win: number,
  users_win_minigame: number,
  users_join_minigame: number,
  total_matches: number,
}

const { getMinigamesData } = mimilandApi;


const Minigame = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: bossData, isLoading: bossLoading } = useQuery(
    ['minigame', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country, filter.minigameID],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getMinigamesData({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
          game: filter.minigameID !== 'All' ? filter.minigameID : undefined,
        },
      });

      return res as unknown as IMinigame[];
    },
  );

  const bossTableData = useMemo(() => {
    return bossData?.map((item) => {
      return [
        {
          value: item.period,
        },
        {
          value: item.daily_users.toLocaleString(),
        },
        {
          value: item.min_betting.toLocaleString(),
        },
        {
          value: item.max_betting.toLocaleString(),
        },
        {
          value: item.total_matches.toLocaleString(),
        },
        {
          value: item.users_join_minigame.toLocaleString(),
        },
        {
          value: (item.total_duration / item.users_join_minigame).toFixed(0),
        },
        {
          value: roundFloatAndFormat(item.total_coin_win / item.users_win_minigame, 0),
        },
      ];
    });
  }, [bossData]);

  const isLoading = bossLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {bossTableData && bossTableData.length > 0 && (
            <RawDataTable2
              isCenterFull
              title="Steps"
              headers={[
                { header: 'Time', subHeaders: null },
                { header: 'DAU', subHeaders: null },
                { header: 'Betting', subHeaders: ['Min', 'Max'] },
                { header: 'Matches', subHeaders: ['Count', 'Users'] },
                { header: 'Avg (Per User)', subHeaders: ['Playtime', 'Coin Win'] },
              ]}
              data={bossTableData.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_rawStr({ value: subItem.value });
                });
              })}
              rawData={bossData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Minigame;
