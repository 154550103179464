import * as XLSX from 'xlsx';

export const allCountries = {
  label: 'All',
  value: 'All',
};

export const payingTypes = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'True',
    value: 'True',
  },
  {
    label: 'False',
    value: 'False',
  },
];
export const resourceTypes = [
  {
    label: 'Gem',
    value: 'gem',
  },
  {
    label: 'Coin',
    value: 'coin',
  },
];
export const huntLevels = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
];

export const gameEvents: {
  [key: string]: {
    label: string;
    value: string;
  }[];
} = {
  DaymareZero: [
    {
      label: '7-Day Beginner',
      value: 'beginner',
    },
    {
      label: 'Star Light',
      value: 'star_light',
    },
    {
      label: 'Mid Autumn',
      value: 'second_event',
    },
    {
      label: 'Battle Pass',
      value: 'battle_pass',
    },
  ]
};

export const MML_Minigames = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Rock Paper Scissors',
    value: 'ROCK_PAPER_SCISSORS',
  },
  {
    label: 'Flip & Match',
    value: 'FLIP_N_MATCH_2',
  },
  {
    label: 'Caro',
    value: 'CARO',
  },
  {
    label: 'Connect 4',
    value: 'CONNECT_4',
  },
  {
    label: 'Tic Tac Toe',
    value: 'TIC_TAC_TOE',
  },
  {
    label: 'Dice Compare',
    value: 'DICE_COMPARE_1',
  }
];

export const supportedTimezones = [
  {
    label: '+7',
    value: 7,
  },
  {
    label: '0',
    value: 0,
  },
  {
    label: '-7',
    value: -7,
  },
];

export const platforms = [
  {
    label: 'iOS',
    value: 'IOS',
  },
  {
    label: 'Android',
    value: 'ANDROID',
  },
];

export const channels = [
  {
    label: 'Facebook',
    value: 'Facebook',
  },
];

export const dayLtvPeriodOptions: { label: string, value: number }[] = [
  { label: '1', value: 1 },
  { label: '3', value: 3 },
  { label: '7', value: 7 },
  { label: '14', value: 14 },
  { label: '30', value: 30 },
  { label: '60', value: 60 },
  { label: '90', value: 90 },
  { label: '120', value: 120 },
];

export const monthLtvPeriodOptions: { label: string, value: number }[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '6', value: 6 },
  { label: '9', value: 9 },
  { label: '12', value: 12 },
];

export const idNameDict: Record<string, string> = {
  'DAY_V_HERO_1': 'Red_Sentinal',
  'DAY_V_HERO_2': 'Rose_Wing',
  'DAY_V_HERO_3': 'Violet_Vixen',
  'DAY_V_HERO_4': 'Periwink',
  'DAY_V_HERO_5': 'Red_Cosmo',
  'DAY_V_HERO_6': 'Baronex',
  'DAY_V_HERO_7': 'Celesnex',
  'DAY_V_HERO_8': 'Azuria',
  'DAY_V_HERO_9': 'Kenshiro',
  'DAY_V_HERO_10': 'Wukong',
  'DAY_N_WEAPON_1': 'Laser_Lancer',
  'DAY_N_WEAPON_2': 'Bone_Breaker',
  'DAY_N_WEAPON_3': 'Moonblade',
  'DAY_N_WEAPON_4': 'Star_Blaster',
  'DAY_T_ZERO': 'Dawn_Armor',
  'DAY_H_ZERO': 'Dawn_Helm',
  'DAY_B_ZERO': 'Dawn_Pants',
  'DAY_S_ZERO': 'Dawn_Boots',
  'DAY_T_ONE': 'Aurora_Armor',
  'DAY_H_ONE': 'Aurora_Helm',
  'DAY_B_ONE': 'Aurora_Pants',
  'DAY_S_ONE': 'Aurora_Boots',
  'DAY_T_TWO': 'Eve_Armor',
  'DAY_H_TWO': 'Eve_Helm',
  'DAY_B_TWO': 'Eve_Pants',
  'DAY_S_TWO': 'Eve_Boots',
};

export const CALENDAR_TEXT_ICON = '📅';

export const dateStrToUnix = (str: string | null | undefined, timezone: number = 0, isEndDate: boolean = false): number | undefined => {
  if (str !== null && str !== undefined) {
    const unix = new Date(str + 'T00:00:00Z').getTime() * 1000 + timezone * 3600000000;
    return isEndDate === true ? unix + 86400000000 : unix;
  }
  return undefined;
};
export const getCurrentUnix = ({ timezone = 0, chunk = 0 }: { timezone?: number, chunk?: number }): number => {
  let unix = Math.floor(Date.now() / 1000);
  if (chunk > 0) unix = Math.floor(unix / chunk) * chunk;
  return unix + timezone * 3600;
};

export const appTimezoneConst: Record<string, number> = {
  DaymareZero: 7,
};

const convertFileName = (title: string) => {
  return title.replace(/ /g, '_').toLowerCase();
};

export const toExcelFileName = (title: string | undefined) => {
  return 'seen_' + (title ? convertFileName(title) : new Date().getTime());
}

export const writeExcelFile = (data: any, fileName: string, type: 'json' | 'string') => {
  const ws = type === 'json' ? XLSX.utils.json_to_sheet(data) : XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const formatTimezoneOffset = (offset: number): string => {
  const intOffset = Math.floor(Math.abs(offset));
  const hours = intOffset.toString().padStart(2, '0');
  const minutes = '00';
  const sign = offset >= 0 ? '+' : '-';
  return `${sign}${hours}:${minutes}`;
}

export const idToName = (id: string): string => {
  return idNameDict[id] ? idNameDict[id] : id;
}

export const roundFloatAndFormat = (value: number, digit: number): string => {
  return Number(value.toFixed(digit)).toLocaleString();
}