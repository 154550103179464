import { useEffect, useMemo, useState } from 'react';
import LayoutContextConsumer from 'contexts/LayoutContext';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import ComingSoonPage from 'pages/main/ComingSoonPage';
import CountrySelect from 'components/shared/CountrySelect';
import FullDatePicker from 'components/shared/FullDatePicker';
import UserIDInput from 'components/shared/UserIDInput';
import TextInput from '../../components/shared/TextInput';
import DropdownSelect from '../../components/shared/DropdownSelect';
import DateRangeV2 from '../../components/shared/DateRangeV2';
import CusCheckbox from '../../components/shared/CusCheckbox';
import dateHelper from 'config/helpers/dateHelper';
import { customTrackingConst } from 'config/constants/customTracking';
import { FilterType, customTrackingExtraFilterConst } from 'config/constants/customTrackingExtraFilter';
import { Radio } from 'antd';
import { MML_Minigames, gameEvents, huntLevels, payingTypes, resourceTypes } from '../../config/constants/general';
import { ICustomTrackingFilter } from '../../config/types/reports';

const { format, getDayBefore } = dateHelper;

const defaultRange = {
  start: format(getDayBefore(new Date(), 10)),
  end: format(getDayBefore(new Date(), 2)),
}
const defaultRangeToday = {
  start: format(getDayBefore(new Date(), 7)),
  end: format(getDayBefore(new Date(), 0)),
}

const CustomTrackingPage = () => {
  const { currentApp } = LayoutContextConsumer();
  useDocumentTitle('Custom Tracking');

  const listTabs = currentApp && customTrackingConst[currentApp.id] ? customTrackingConst[currentApp.id] : [];
  const listEvents = currentApp && gameEvents[currentApp.id] ? gameEvents[currentApp.id] : [];
  const initFilter: ICustomTrackingFilter = {
    isFirstLoad: true,
    rangeDate: {},
    rangeDate2: {},
    country: '',
    userID: '',
    userName: '',
    paying: '',
    event: '',
    isTutSessionOnly: false,
    chestID: '',
    resourceType: '',
    level: '',
    heroID: '',
    minigameID: '',
  };

  const [tab, setTab] = useState<string>(listTabs[0] ? listTabs[0].id : '');
  const [rangeDate, setRangeDate] = useState<{ start?: string; end?: string; }>();
  const [rangeDate2, setRangeDate2] = useState<{ start?: string; end?: string; }>();
  const [selectedCountry, setSelectedCountry] = useState<string>('All');
  const [userID, setUserID] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [paying, setPaying] = useState<string>('All');
  const [event, setEvent] = useState<string>(listEvents && listEvents.length > 0 ? listEvents[0].value : '');
  const [isTutSessionOnly, setTutSessionOnly] = useState<boolean>(false);
  const [chestID, setChestID] = useState<string>('');
  const [resourceType, setResourceType] = useState<string>(resourceTypes[0].value);
  const [level, setLevel] = useState<string>(huntLevels[0].value);
  const [heroID, setHeroID] = useState<string>('');
  const [minigameID, setMinigameID] = useState<string>(MML_Minigames[0].value);
  const [selectedFilter, setSelectedFilter] = useState<ICustomTrackingFilter>(initFilter);

  const appExtraFilters = (currentApp && customTrackingExtraFilterConst[currentApp.id]) ? customTrackingExtraFilterConst[currentApp.id] : {};
  const tabExtraFilters = (appExtraFilters && appExtraFilters[tab]) ? appExtraFilters[tab] : [];

  const generateFilter = (): ICustomTrackingFilter => {
    return {
      isFirstLoad: false,
      rangeDate: rangeDate,
      rangeDate2: rangeDate2,
      country: selectedCountry,
      userID: userID,
      userName: userName,
      paying: paying,
      event: event,
      isTutSessionOnly: isTutSessionOnly,
      chestID: chestID,
      resourceType: resourceType,
      level: level,
      heroID: heroID,
      minigameID: minigameID,
    };
  };

  /*const { data: versionHistoryData } = useQuery(
    ['version-history', currentApp, platform, rangeDate],
    async () => {
      const response = await versionHistoryApi.getAll({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          startDate: rangeDate?.start,
          endDate: rangeDate?.end,
        },
      });
      return response as unknown as IVersionHistory[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );*/

  useIsomorphicLayoutEffect(() => {
    setTab(listTabs && listTabs[0] ? listTabs[0].id : '');
  }, [listTabs]);

  useEffect(() => {
    if (tab) {
      const tabInfo = listTabs.find((item) => item.id === tab);
      if (tabInfo) {
        setSelectedFilter(initFilter);
        if (tabInfo.defaultRange) {
          setRangeDate(tabInfo.defaultRange);
        } else {
          setRangeDate(currentApp?.id === 'DaymareZero' ? defaultRangeToday : defaultRange);
        }
      }
    }
  }, [tab])


  const renderTab = useMemo(() => {
    const tabInfo = listTabs.find((item) => item.id === tab);
    if (tabInfo) {
      const TabComponent = tabInfo.component;
      return (<TabComponent filter={selectedFilter} />);
    }
  }, [tab, selectedFilter]);

  if (!currentApp || listTabs.length === 0) {
    return <ComingSoonPage />;
  }

  return (
    <div>
      <h3 className="page-section-title">Custom Tracking</h3>
      <Radio.Group
        value={tab}
        buttonStyle="solid"
        className="mt-4"
        onChange={(e: any) => {
          setTab(e.target.value);
        }}
      >
        {listTabs
          .filter((item) => item.type !== 'event')
          .map((item) => (
            <Radio.Button key={item.id} value={item.id}>
              {item.name}
            </Radio.Button>
          ))}
      </Radio.Group>

      <div className="my-4 flex flex-wrap gap-6">
      {
        (tabExtraFilters && tabExtraFilters.length > 0)
        ? tabExtraFilters.map((item) => {
          if (item.type === FilterType.Apply) {
            return (<button className="primary-button" onClick={() => {
              setSelectedFilter(generateFilter());
            }}>Apply</button>);
          }
          if (item.type === FilterType.DefaultDate) {
            return (<FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />);
          }
          if (item.type === FilterType.Country) {
            return (<CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />);
          }
          if (item.type === FilterType.UserID) {
            return (<UserIDInput value={userID as unknown as string} onValueChanged={setUserID} />);
          }
          if (item.type === FilterType.UserName) {
            return (<TextInput value={userName as unknown as string} onValueChanged={setUserName} />);
          }
          if (item.type === FilterType.Paying) {
            return (<DropdownSelect value={paying as unknown as string} onValueChanged={setPaying} options={payingTypes} title='Paying Types' />);
          }
          if (item.type === FilterType.DateRange1) {
            const title = item.title ?? 'Select Date';
            return (<DateRangeV2 rangeDate={rangeDate} setRangeDate={setRangeDate} title={title} />);
          }
          if (item.type === FilterType.DateRange2) {
            const title = item.title ?? 'Account Created';
            return (<DateRangeV2 rangeDate={rangeDate2} setRangeDate={setRangeDate2} title={title} />);
          }
          if (item.type === FilterType.TutorialSessionOnly) {
            return (<CusCheckbox value={isTutSessionOnly} onValueChanged={setTutSessionOnly} title={item.title} />);
          }
          if (item.type === FilterType.Event) {
            return (<DropdownSelect value={event as unknown as string} onValueChanged={setEvent} options={listEvents} title='Event' />);
          }
          if (item.type === FilterType.ChestID) {
            return (<TextInput value={chestID as unknown as string} onValueChanged={setChestID} title='Chest ID' />);
          }
          if (item.type === FilterType.ResourceType) {
            return (<DropdownSelect value={resourceType} onValueChanged={setResourceType} options={resourceTypes} title='Resource Type' />);
          }
          if (item.type === FilterType.Level) {
            return (<DropdownSelect value={level} onValueChanged={setLevel} options={huntLevels} title='Level' />);
          }
          if (item.type === FilterType.HeroID) {
            return (<TextInput value={heroID as unknown as string} onValueChanged={setHeroID} title='Hero ID' />);
          }
          if (item.type === FilterType.MinigameID) {
            return (<DropdownSelect value={minigameID} onValueChanged={setMinigameID} options={MML_Minigames} title='Minigame' />);
          }
          return (<></>);
        })
        : (<>
          <FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
          <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
        </>)
      }
      </div>
      {renderTab}
    </div>
    
  );
};

export default CustomTrackingPage;
