import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from '../../charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IFeaturePlaytime {
  avg_playtime_stage: number,
  avg_playtime_boss: number,
  avg_playtime_pvp: number,
  avg_playtime_hunt: number,
}

const { getAvgPlaytime } = daymareZeroApi;

const DaymarePlaytimeOfFeatures = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['feature-playtime', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getAvgPlaytime({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IFeaturePlaytime[];
    },
  );

  const secToTimeStr = (second: number) => {
    const min = Math.floor(second / 60);
    return `${min}m ${second % 60}s`;
  };

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: secToTimeStr(item.avg_playtime_stage) },
        { value: secToTimeStr(item.avg_playtime_boss) },
        { value: secToTimeStr(item.avg_playtime_pvp) },
        { value: secToTimeStr(item.avg_playtime_hunt) },
      ];
    });
  }, [rawData]);

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Avg Playtime"
              headers={[
                'Stage',
                'Boss',
                'PvP',
                'Hunt',
              ]}
              data={tableData.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_rawStr({ value: subItem.value });
                });
              })}
              rawData={rawData?.map(item => {
                return {
                  Stage: secToTimeStr(item.avg_playtime_stage),
                  Boss: secToTimeStr(item.avg_playtime_boss),
                  PvP: secToTimeStr(item.avg_playtime_pvp),
                  Hunt: secToTimeStr(item.avg_playtime_hunt),
                };
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymarePlaytimeOfFeatures;
