import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IMergeRelic1 {
  rarity: string,
  relic_use: number,
  success: number,
  times: number,
}

interface IMergeRelic2 {
  period: string,
  times: number,
  users: number,
  DAU: number,
}

const { getMergeRelicData1, getMergeRelicData2 } = daymareZeroApi;

const DaymareMergeRelic = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData1, isLoading: data1Loading } = useQuery(
    [
      'merge-relic1',
      platform,
      filter.rangeDate?.start, filter.rangeDate?.end,
      filter.rangeDate2?.start, filter.rangeDate2?.end,
      filter.country,
    ],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getMergeRelicData1({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });

      return res as unknown as IMergeRelic1[];
    },
  );

  const { data: rawData2, isLoading: data2Loading } = useQuery(
    [
      'merge-relic2',
      platform,
      filter.rangeDate?.start, filter.rangeDate?.end,
      filter.rangeDate2?.start, filter.rangeDate2?.end,
      filter.country,
    ],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getMergeRelicData2({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });

      return res as unknown as IMergeRelic2[];
    },
  );

  const tableData1 = useMemo(() => {
    return rawData1?.map((item) => {
      return [
        { value: item.rarity },
        { value: item.relic_use },
        { value: item.success === 1 ? 'Success' : 'Failed' },
        { value: item.times },
      ];
    });
  }, [rawData1]);

  const tableData2 = useMemo(() => {
    return rawData2?.map((item) => {
      return [
        { value: item.period },
        { value: item.DAU },
        { value: item.times },
        { value: `${item.users} (${(item.users / item.DAU).toFixed(2)}%)` },
      ];
    });
  }, [rawData2]);

  const isLoading = data1Loading || data2Loading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {tableData2 && tableData2.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Merge Relic"
              headers={[
                'Date',
                'DAU',
                'Times',
                'Users',
              ]}
              data={tableData2.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_rawStr({ value: subItem.value as unknown as string });
                });
              })}
              rawData={rawData2}
            />
          )}
          {tableData1 && tableData1.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Success Rate"
              headers={[
                'Rarity',
                'Relic Used',
                'Result',
                'Times',
              ]}
              data={tableData1.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_rawStr({ value: subItem.value as unknown as string });
                });
              })}
              rawData={rawData1?.map(e => {
                return {
                  rarity: e.rarity,
                  relic_use: e.relic_use,
                  result: e.success === 1 ? 'Success' : 'Failed',
                  times: e.times,
                }
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareMergeRelic;
