export enum FilterType {
  None, //////DMZ
  Apply,
  DefaultDate,
  DateRange1,
  DateRange2,
  MonthRange1,
  SingleDate1,
  Country,
  UserID,
  UserName,
  Paying,
  DayLtvPeriod,
  MonthLtvPeriod,
  TutorialSessionOnly,
  Event,
  PlatformF, //platform in filter section.. it is different with platform in context
  CountryF, //this value will update the render when changed
  Channel,
  ChestID,
  ResourceType,
  Level,
  ActiveIn,
  HeroID,
  MinigameID, //////MML
}
export interface CustomTrackingFilter {
  type: FilterType;
  defaultValue?: string | number;
  title?: string;
}

export const customTrackingExtraFilterConst: Record<string, Record<string, CustomTrackingFilter[]>> = {
  SkyDancer2: {
    DailyReport: [
      { type: FilterType.SingleDate1 },
      { type: FilterType.Apply },
    ]
  },
  Mimiland: {
    Minigame: [
      { type: FilterType.DefaultDate },
      { type: FilterType.Country },
      { type: FilterType.MinigameID },
      { type: FilterType.Apply },
    ],
    Resources: [
      { type: FilterType.DefaultDate },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ]
  },
  DaymareZero: {
    UserProfile: [
      { type: FilterType.DateRange1, title: 'Created Date' },
      { type: FilterType.DateRange2, title: 'Last Action' },
      { type: FilterType.Country },
      { type: FilterType.UserID },
      { type: FilterType.UserName },
      { type: FilterType.Paying },
      { type: FilterType.Apply },
    ],
    UserEquipment: [
      { type: FilterType.UserID },
      { type: FilterType.Apply },
    ],
    Resources: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.ResourceType },
      { type: FilterType.Apply },
    ],
    IAPSuccessRawData: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.UserID },
      { type: FilterType.ChestID },
      { type: FilterType.Apply },
    ],
    IAPPackBuyingDetails: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    AdsDetails: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    StageByPeriod: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    StageByStage: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    Gacha: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    OpenChest: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.ChestID },
      { type: FilterType.Apply },
    ],
    Boss: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    B7DayQuest: [
      { type: FilterType.DateRange1 },
      { type: FilterType.DateRange2 },
      { type: FilterType.Country },
      { type: FilterType.Event },
      { type: FilterType.Apply },
    ],
    DayLTV: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.DayLtvPeriod },
      { type: FilterType.Apply },
    ],
    MonthLTV: [
      { type: FilterType.MonthRange1 },
      { type: FilterType.Country },
      { type: FilterType.MonthLtvPeriod },
      { type: FilterType.Apply },
    ],
    LastAction: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.TutorialSessionOnly, title: 'Users quit after First Session' },
      { type: FilterType.Apply },
    ],
    Pvp: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    TopPU: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    GuiClick: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    DailyReport: [
      { type: FilterType.SingleDate1 },
      { type: FilterType.Apply },
    ],
    DailyReport1: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Apply },
    ],
    MonthlyReport: [
      { type: FilterType.MonthRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    CampaignReport: [
      { type: FilterType.PlatformF },
      { type: FilterType.CountryF },
      { type: FilterType.Channel },
    ],
    Hunt: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Level },
      { type: FilterType.Apply },
    ],
    RemainingResources: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.ActiveIn },
      { type: FilterType.Apply },
    ],
    AvgPlaytime: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    RetryToPassStage: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    ArcanaHeroAcquire: [
      { type: FilterType.HeroID },
      { type: FilterType.Apply },
    ],
    Awaken: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    MergeRelic: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ],
    OfflineEarning: [
      { type: FilterType.DateRange1 },
      { type: FilterType.Country },
      { type: FilterType.Apply },
    ]
  }
};