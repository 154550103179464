import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import numberHelper from 'config/helpers/numberHelper';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

const { convertText } = numberHelper;

interface Props {
  filter: ICustomTrackingFilter;
}

interface IAdsSteptimes {
  time: string
  platform: string
  DAU: number
  all_times_click: number
  all_users_click: number
  all_times_ad_impression: number
  all_users_ad_impression: number
  chest_equip_times_click: number
  chest_equip_users_click: number
  chest_hero_times_click: number
  chest_hero_users_click: number
  gem_pack_times_click: number
  gem_pack_users_click: number
  coin_pack_times_click: number
  coin_pack_users_click: number
  boss_ticket_times_click: number
  boss_ticket_users_click: number
  limited_pack_times_click: number
  limited_pack_users_click: number
  refill_energy_times_click: number
  refill_energy_users_click: number
  undefined_times_click: number
  undefined_users_click: number
}

const AdsItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

const { getAdsDetails } = daymareZeroApi;

const DaymareAdsDetail = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: packData, isLoading: packLoading } = useQuery(
    ['iap-pack', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getAdsDetails({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
        },
      });
      return res as unknown as IAdsSteptimes[];
    }
  );

  const packTableData = useMemo(() => {
    return packData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.all_times_click,
          users: `${convertText(item.all_users_click)} (${((item.all_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.all_times_ad_impression,
          users: `${convertText(item.all_users_ad_impression)} (${((item.all_users_ad_impression / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.chest_equip_times_click,
          users: `${convertText(item.chest_equip_users_click)} (${((item.chest_equip_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.chest_hero_times_click,
          users: `${convertText(item.chest_hero_users_click)} (${((item.chest_hero_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.gem_pack_times_click,
          users: `${convertText(item.gem_pack_users_click)} (${((item.gem_pack_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.coin_pack_times_click,
          users: `${convertText(item.coin_pack_users_click)} (${((item.coin_pack_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.boss_ticket_times_click,
          users: `${convertText(item.boss_ticket_users_click)} (${((item.boss_ticket_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.limited_pack_times_click,
          users: `${convertText(item.limited_pack_users_click)} (${((item.limited_pack_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.refill_energy_times_click,
          users: `${convertText(item.refill_energy_users_click)} (${((item.refill_energy_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.undefined_times_click,
          users: `${convertText(item.undefined_users_click)} (${((item.undefined_users_click / item.DAU) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [packData]);

  const isLoading = packLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {packTableData && packTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Ads Steptimes"
              headers={[
                'Date',
                'Platform',
                'Total Request',
                'Total Success',
                'Chest Equip',
                'Chest Hero',
                'Gem',
                'Coin',
                'Boss Ticket',
                'Limited Pack',
                'Refill Energy',
                'Undefined',
              ]}
              data={packTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <AdsItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={packData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareAdsDetail;
