import { Checkbox } from "antd";
import { BiCoinStack, BiTimer, BiUser } from "react-icons/bi";
import { RiPercentLine } from "react-icons/ri";

const getIconComponent = (icon: string) => {
  switch (icon) {
    case 'users':
      return <BiUser className="text-12" />;
    case 'times':
      return <BiTimer className="text-12" />;
    case 'coins':
      return <BiCoinStack className="text-12" />;
    case 'percent':
      return <RiPercentLine className="text-12" />;
    default:
      return null;
  }
};

export const itemToCell_rawStr = ({ value }: { value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1 whitespace-nowrap">
        <p className="text-14">{value}</p>
      </div>
    </div>
  );
};
export const itemToCell_rawStr_2lineWrap = ({ value }: { value: string }) => {
  let slice1 = '', slice2 = '';
  if (value.length > 50) {
    slice1 = value.slice(0, 34);
    slice2 = value.slice(34, 60) + '...';
  } else {
    slice1 = value;
  }
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    alert('Copied to clipboard!');
  };
  return (
    <div className="relative w-[300px] text-left">
      <div className="text-left">
        <p className="mb-0 text-14">{slice1}</p>
        <p className="mt-0 text-14">{slice2}</p>
      </div>
      <button
        onClick={handleCopy}
        className="absolute bottom-0 right-0 underline cursor-pointer bg-transparent border-none p-0"
        style={{ color: 'blue' }}
      >
        Copy
      </button>
    </div>
  );
};

export const itemToCell_icon = ({ value, icon }: { value: string, icon: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1 whitespace-nowrap">
        {icon !== '' ? getIconComponent(icon) : null}
        <p className="text-14">{value}</p>
      </div>
    </div>
  );
};

export const itemToCell_checkbox = ({ value }: { value: boolean }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <Checkbox checked={value} disabled />
      </div>
    </div>
  );
};

export const itemToCell_timeUsers = ({ times, users }: { times: string; users: string; }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiTimer className="text-12" />
        <p className="text-14">{times}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};
export const itemToCell_currencyUsers = ({ amount, users }: { amount: string; users: string; }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiCoinStack className="text-12" />
        <p className="text-14">{amount}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

export const itemToCell_rows = ({ rows }: { rows: { icon: string, value: string }[] }) => {
  return (
    <div className="">
    {
      rows.map((it) => {
        return it.value && it.value !== ''
          ? (<div className="flex items-center gap-1 whitespace-nowrap">
              {it.icon !== '' ? getIconComponent(it.icon) : null}
              <p className="text-14">{it.value}</p>
            </div>)
          : (<></>);
      })
    }
    </div>
  );
};