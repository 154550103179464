import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import reportApi from 'config/api/bigquery/reportApi';
import Loading from 'components/shared/Loading';
import ComingSoonPage from 'pages/main/ComingSoonPage';
import { IReportFilter } from '../../../config/types/reports';
import { itemToCell_rawStr } from '../../layout/protected/Custom';

interface Props {
  filter: IReportFilter;
}

type TReport = {
  date_str: string;
  a1: number;
  n1: number;
  rr1: number;
  revenue_ads1: number;
  revenue_iap1: number;
  revenue_total1: number;
  pu_ads1: number;
  pu_iap1: number;
  pu_total1: number;
  new_pu_iap1: number;
  arppu_ads1: number;
  arppu_iap1: number;
  cr_ads1: number;
  cr_iap1: number;
  acu: number;
  pcu: number;
  a7: number;
  n7: number;
  revenue_ads7: number;
  revenue_iap7: number;
  revenue_total7: number;
  pu_ads7: number;
  pu_iap7: number;
  pu_total7: number;
  new_pu_iap7: number;
  a30: number;
  n30: number;
  revenue_ads30: number;
  revenue_iap30: number;
  revenue_total30: number;
  pu_ads30: number;
  pu_iap30: number;
  pu_total30: number;
  new_pu_iap30: number;
};

const DailyReport1 = ({ filter }: Props) => {
  const { currentApp } = LayoutContextConsumer();
  const isValidGame = ['DaymareZero'].includes(currentApp?.id ?? '');

  const { data: todayData, isLoading: todayLoading } = useQuery({
    queryKey: ['daily-report1', filter.rangeDate?.start, filter.rangeDate?.end],
    queryFn: async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end || isValidGame === false) {
        return [];
      }
      const res = await reportApi.getDailyReport({
        params: {
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
        },
      });
      return res as unknown as TReport[];
    },
  });

  const tableData = useMemo(() => {
    const reportTemplate = [
      ['A1', ...(todayData ?? []).map((date) => date.a1)],
      ['N1', ...(todayData ?? []).map((date) => date.n1)],
      [
        'RR1',
        ...(todayData ?? []).map((date) => (typeof date.rr1 === "number") ? <span>{date.rr1 + "%"}</span> : date.rr1)
      ],
      ['Ads Revenue in 1 day', ...(todayData ?? []).map((date) => date.revenue_ads1)],
      ['IAP Revenue in 1 day', ...(todayData ?? []).map((date) => date.revenue_iap1)],
      ['Total Revenue in 1 day', ...(todayData ?? []).map((date) => date.revenue_total1)],
      ['Ads PU1', ...(todayData ?? []).map((date) => date.pu_ads1)],
      ['IAP PU1', ...(todayData ?? []).map((date) => date.pu_iap1)],
      ['Both PU1', ...(todayData ?? []).map((date) => date.pu_total1)],
      ['New IAP PU1', ...(todayData ?? []).map((date) => date.new_pu_iap1)],
      ['ARPPU1 Ads', ...(todayData ?? []).map((date) => date.arppu_ads1)],
      ['ARPPU1 IAP', ...(todayData ?? []).map((date) => date.arppu_iap1)],
      ['Conversion Rate Ads 1 day(%)', ...(todayData ?? []).map((date) => (typeof date.cr_ads1 === "number") ? <span>{date.cr_ads1 + "%"}</span> : date.cr_ads1)],
      ['Conversion Rate IAP 1 day(%)', ...(todayData ?? []).map((date) => (typeof date.cr_iap1 === "number") ? <span>{date.cr_iap1 + "%"}</span> : date.cr_iap1)],

      ['ACU', ...(todayData ?? []).map((date) => date.acu)],
      ['PCU', ...(todayData ?? []).map((date) => date.pcu)],

      ['A7', ...(todayData ?? []).map((date) => date.a7)],
      ['N7', ...(todayData ?? []).map((date) => date.n7)],
      ['Ads Revenue in 7 days', ...(todayData ?? []).map((date) => date.revenue_ads7)],
      ['IAP Revenue in 7 days', ...(todayData ?? []).map((date) => date.revenue_iap7)],
      ['Total Revenue in 7 days', ...(todayData ?? []).map((date) => date.revenue_total7)],
      ['Ads PU7', ...(todayData ?? []).map((date) => date.pu_ads7)],
      ['IAP PU7', ...(todayData ?? []).map((date) => date.pu_iap7)],
      ['Both PU7', ...(todayData ?? []).map((date) => date.pu_total7)],
      ['New IAP PU7', ...(todayData ?? []).map((date) => date.new_pu_iap7)],

      ['A30', ...(todayData ?? []).map((date) => date.a30)],
      ['N30', ...(todayData ?? []).map((date) => date.n30)],
      ['Ads PU30', ...(todayData ?? []).map((date) => date.pu_ads30)],
      ['IAP PU30', ...(todayData ?? []).map((date) => date.pu_iap30)],
      ['Both PU30', ...(todayData ?? []).map((date) => date.pu_total30)],
      ['New IAP PU30', ...(todayData ?? []).map((date) => date.new_pu_iap30)],
      ['Ads Revenue in 30 days', ...(todayData ?? []).map((date) => date.revenue_ads30)],
      ['IAP Revenue in 30 days', ...(todayData ?? []).map((date) => date.revenue_iap30)],
      ['Total Revenue in 30 days', ...(todayData ?? []).map((date) => date.revenue_total30)],
    ];
    return reportTemplate;
  }, [todayData]);

  const isLoading = todayLoading;

  if (isValidGame === false) {
    return <ComingSoonPage />;

  }

  return (
    <div className="pb-4">
      {isLoading && <Loading />}
      {tableData && (
        <RawDataTable
          headers={['', ...(todayData ?? []).map((date) => date.date_str)]}
          rawData={[
            ['', ...(todayData ?? []).map((date) => date.date_str)],
            ...tableData.map((row) => row.map((cell) => typeof cell === 'object' ? (cell as JSX.Element).props.children : cell))
          ]}
          title="Report"
          data={tableData.map((item) => {
            return item.map((subItem) => {
              return itemToCell_rawStr({ value: subItem as unknown as string });
            });
          })}
        />
      )}
    </div>
  );
};

export default DailyReport1;
