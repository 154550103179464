import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { itemToCell_checkbox, itemToCell_rawStr } from '../../../layout/protected/Custom';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IOpenChest {
  period: string,
  user_id: string,
  user_level: number,
  chest_id: string,
  amount: number,
  pickable: boolean,
  rewards: { item: string, amount: number }[];
}

const { getOpenChest } = daymareZeroApi;

const DaymareOpenChest = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['open-chest', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country, filter.chestID],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getOpenChest({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
          chestID: filter.chestID,
        },
      });
      return res as unknown as IOpenChest[];
    },
  );

  const rewardsToStr = (rewards: { item: string, amount: number }[]): string => {
    const strs: string[] = [];
    rewards.forEach(item => {
      strs.push(`${item.item}: ${item.amount}`)
    });
    return strs.join(', ');
  };

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.period },
        { value: item.user_id },
        { value: item.user_level },
        { value: item.chest_id },
        { value: item.amount },
        { value: item.pickable },
        { value: rewardsToStr(item.rewards) },
      ];
    });
  }, [rawData]);

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Open Chest"
              headers={[
                'Time',
                'Uid',
                'Level',
                'Chest ID',
                'Amount',
                'Pickable',
                'Rewards',
                ]}
                data={tableData.map((item) => {
                  return item.map((subItem) => {
                    if (typeof subItem.value === 'boolean') {
                      return itemToCell_checkbox({ value: subItem.value });
                    } else {
                      return itemToCell_rawStr({ value: subItem.value as string });
                    }
                  });
                })}
                rawData={rawData?.map((item) => {
                  return {
                    period: item.period,
                    user_id: item.user_id,
                    user_level: item.user_level,
                    chest_id: item.chest_id,
                    amount: item.amount,
                    pickable: item.pickable,
                    rewards: rewardsToStr(item.rewards)
                  };
                })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareOpenChest;
