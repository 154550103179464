import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable2 from '../../charts/RawDataTable2';
import Loading from 'components/shared/Loading';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { ICustomTrackingFilter } from '../../../../config/types/reports';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';
import { idToName } from '../../../../config/constants/general';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IUserEquipment {
  user_id: string,
  s1_id: string, s1_rarity: string, s1_level: string,
  s1_weapon_id: string, s1_weapon_rarity: string, s1_weapon_level: string,
  s1_head_id: string, s1_head_rarity: string, s1_head_level: string,
  s1_top_id: string, s1_top_rarity: string, s1_top_level: string,
  s1_bottom_id: string, s1_bottom_rarity: string, s1_bottom_level: string,
  s1_shoes_id: string, s1_shoes_rarity: string, s1_shoes_level: string,
  s2_id: string, s2_rarity: string, s2_level: string,
  s2_weapon_id: string, s2_weapon_rarity: string, s2_weapon_level: string,
  s2_head_id: string, s2_head_rarity: string, s2_head_level: string,
  s2_top_id: string, s2_top_rarity: string, s2_top_level: string,
  s2_bottom_id: string, s2_bottom_rarity: string, s2_bottom_level: string,
  s2_shoes_id: string, s2_shoes_rarity: string, s2_shoes_level: string,
  s3_id: string, s3_rarity: string, s3_level: string,
  s3_weapon_id: string, s3_weapon_rarity: string, s3_weapon_level: string,
  s3_head_id: string, s3_head_rarity: string, s3_head_level: string,
  s3_top_id: string, s3_top_rarity: string, s3_top_level: string,
  s3_bottom_id: string, s3_bottom_rarity: string, s3_bottom_level: string,
  s3_shoes_id: string, s3_shoes_rarity: string, s3_shoes_level: string,
  s4_id: string, s4_rarity: string, s4_level: string,
  s4_weapon_id: string, s4_weapon_rarity: string, s4_weapon_level: string,
  s4_head_id: string, s4_head_rarity: string, s4_head_level: string,
  s4_top_id: string, s4_top_rarity: string, s4_top_level: string,
  s4_bottom_id: string, s4_bottom_rarity: string, s4_bottom_level: string,
  s4_shoes_id: string, s4_shoes_rarity: string, s4_shoes_level: string,
  s5_id: string, s5_rarity: string, s5_level: string,
  s5_weapon_id: string, s5_weapon_rarity: string, s5_weapon_level: string,
  s5_head_id: string, s5_head_rarity: string, s5_head_level: string,
  s5_top_id: string, s5_top_rarity: string, s5_top_level: string,
  s5_bottom_id: string, s5_bottom_rarity: string, s5_bottom_level: string,
  s5_shoes_id: string, s5_shoes_rarity: string, s5_shoes_level: string,
}

const { getUserEquipment } = daymareZeroApi;

const DaymareUserEquipment = ({ filter }: Props) => {
  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['user-equipment', filter?.userID],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.userID) {
        return [];
      }
      const res = await getUserEquipment({
        data: {
          userID: filter.userID,
        }
      });
      return res as unknown as IUserEquipment[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.user_id },
        { value: (item.s1_id !== '') ? `${idToName(item.s1_id)} | ${item.s1_rarity} | ${item.s1_level}` : '' },
        { value: (item.s1_weapon_id !== '') ? `${idToName(item.s1_weapon_id)} | ${item.s1_weapon_rarity} | ${item.s1_weapon_level}` : '' },
        { value: (item.s1_head_id !== '') ? `${idToName(item.s1_head_id)} | ${item.s1_head_rarity} | ${item.s1_head_level}` : '' },
        { value: (item.s1_top_id !== '') ? `${idToName(item.s1_top_id)} | ${item.s1_top_rarity} | ${item.s1_top_level}` : '' },
        { value: (item.s1_bottom_id !== '') ? `${idToName(item.s1_bottom_id)} | ${item.s1_bottom_rarity} | ${item.s1_bottom_level}` : '' },
        { value: (item.s1_shoes_id !== '') ? `${idToName(item.s1_shoes_id)} | ${item.s1_shoes_rarity} | ${item.s1_shoes_level}` : '' },
        { value: (item.s2_id !== '') ? `${idToName(item.s2_id)} | ${item.s2_rarity} | ${item.s2_level}` : '' },
        { value: (item.s2_weapon_id !== '') ? `${idToName(item.s2_weapon_id)} | ${item.s2_weapon_rarity} | ${item.s2_weapon_level}` : '' },
        { value: (item.s2_head_id !== '') ? `${idToName(item.s2_head_id)} | ${item.s2_head_rarity} | ${item.s2_head_level}` : '' },
        { value: (item.s2_top_id !== '') ? `${idToName(item.s2_top_id)} | ${item.s2_top_rarity} | ${item.s2_top_level}` : '' },
        { value: (item.s2_bottom_id !== '') ? `${idToName(item.s2_bottom_id)} | ${item.s2_bottom_rarity} | ${item.s2_bottom_level}` : '' },
        { value: (item.s2_shoes_id !== '') ? `${idToName(item.s2_shoes_id)} | ${item.s2_shoes_rarity} | ${item.s2_shoes_level}` : '' },
        { value: (item.s3_id !== '') ? `${idToName(item.s3_id)} | ${item.s3_rarity} | ${item.s3_level}` : '' },
        { value: (item.s3_weapon_id !== '') ? `${idToName(item.s3_weapon_id)} | ${item.s3_weapon_rarity} | ${item.s3_weapon_level}` : '' },
        { value: (item.s3_head_id !== '') ? `${idToName(item.s3_head_id)} | ${item.s3_head_rarity} | ${item.s3_head_level}` : '' },
        { value: (item.s3_top_id !== '') ? `${idToName(item.s3_top_id)} | ${item.s3_top_rarity} | ${item.s3_top_level}` : '' },
        { value: (item.s3_bottom_id !== '') ? `${idToName(item.s3_bottom_id)} | ${item.s3_bottom_rarity} | ${item.s3_bottom_level}` : '' },
        { value: (item.s3_shoes_id !== '') ? `${idToName(item.s3_shoes_id)} | ${item.s3_shoes_rarity} | ${item.s3_shoes_level}` : '' },
        { value: (item.s4_id !== '') ? `${idToName(item.s4_id)} | ${item.s4_rarity} | ${item.s4_level}` : '' },
        { value: (item.s4_weapon_id !== '') ? `${idToName(item.s4_weapon_id)} | ${item.s4_weapon_rarity} | ${item.s4_weapon_level}` : '' },
        { value: (item.s4_head_id !== '') ? `${idToName(item.s4_head_id)} | ${item.s4_head_rarity} | ${item.s4_head_level}` : '' },
        { value: (item.s4_top_id !== '') ? `${idToName(item.s4_top_id)} | ${item.s4_top_rarity} | ${item.s4_top_level}` : '' },
        { value: (item.s4_bottom_id !== '') ? `${idToName(item.s4_bottom_id)} | ${item.s4_bottom_rarity} | ${item.s4_bottom_level}` : '' },
        { value: (item.s4_shoes_id !== '') ? `${idToName(item.s4_shoes_id)} | ${item.s4_shoes_rarity} | ${item.s4_shoes_level}` : '' },
        { value: (item.s5_id !== '') ? `${idToName(item.s5_id)} | ${item.s5_rarity} | ${item.s5_level}` : '' },
        { value: (item.s5_weapon_id !== '') ? `${idToName(item.s5_weapon_id)} | ${item.s5_weapon_rarity} | ${item.s5_weapon_level}` : '' },
        { value: (item.s5_head_id !== '') ? `${idToName(item.s5_head_id)} | ${item.s5_head_rarity} | ${item.s5_head_level}` : '' },
        { value: (item.s5_top_id !== '') ? `${idToName(item.s5_top_id)} | ${item.s5_top_rarity} | ${item.s5_top_level}` : '' },
        { value: (item.s5_bottom_id !== '') ? `${idToName(item.s5_bottom_id)} | ${item.s5_bottom_rarity} | ${item.s5_bottom_level}` : '' },
        { value: (item.s5_shoes_id !== '') ? `${idToName(item.s5_shoes_id)} | ${item.s5_shoes_rarity} | ${item.s5_shoes_level}` : '' },
      ];
    });
  }, [rawData]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable2
                  isCenterFull
                  title="User Equipment"
                  headers={[
                    { header: 'User ID', subHeaders: null },
                    { header: 'Slot 1', subHeaders: ['Hero', 'Weapon', 'Head', 'Top', 'Bottom', 'Shoes'] },
                    { header: 'Slot 2', subHeaders: ['Hero', 'Weapon', 'Head', 'Top', 'Bottom', 'Shoes'] },
                    { header: 'Slot 3', subHeaders: ['Hero', 'Weapon', 'Head', 'Top', 'Bottom', 'Shoes'] },
                    { header: 'Slot 4', subHeaders: ['Hero', 'Weapon', 'Head', 'Top', 'Bottom', 'Shoes'] },
                    { header: 'Slot 5', subHeaders: ['Hero', 'Weapon', 'Head', 'Top', 'Bottom', 'Shoes'] },
                  ]}
                  data={guiFormOfRawData.map((item) => {
                    return item.map((subItem) => {
                      if (subItem && subItem.value)
                        return itemToCell_rawStr({ value: subItem.value });
                      else
                        return (<></>);
                    });
                  })}
                  rawData={rawData}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default DaymareUserEquipment;
