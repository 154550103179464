import React, { ChangeEvent } from 'react';
import { Input } from 'antd';

interface ITextInput {
  value: string;
  onValueChanged: (newValue: string) => void;
  title?: string;
}

const TextInput = ({ value, onValueChanged, title = 'User Name' }: ITextInput) => {

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onValueChanged(e.target.value);
  };

  return (
    <div className="">
      <h6 className="mb-2 text-16 font-[500]">{title}</h6>
      <Input
        id={`txt-${title.toLowerCase().replace(' ', '-')}`}
        type="text" value={value} style={{ width: '200px' }}
        onChange={onInputChange}
      />
    </div>
  );
};

export default TextInput;