import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import numberHelper from '../../../../config/helpers/numberHelper';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IGuiClick {
  period: string;
  id: string;
  times: number;
  users: number;
  daily_users: number;
}

const { getGuiClick } = daymareZeroApi;
const { convertText } = numberHelper;

const DaymareGuiClick = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['gui-click', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getGuiClick({
        params: {
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          platform: platform === 'unified' ? undefined : platform,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IGuiClick[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.period },
        { value: item.id },
        { value: item.times.toString() },
        { value: `${convertText(item.users)} (${((item.users / item.daily_users) * 100).toFixed(1)}%)` },
      ];
    });
  }, [rawData]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="Top PU"
                headers={[
                  'Time',
                  'Gui ID',
                  'Times',
                  'Users',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return itemToCell_rawStr({ value: subItem.value });
                  });
                })}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default DaymareGuiClick;
