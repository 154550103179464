import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiCoinStack, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import numberHelper from 'config/helpers/numberHelper';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IStage {
  period: string;
  platform: string;
  country: string;
  daily_users: number;
  start_times: number,
  start_users: number,
  win_times: number,
  win_users: number,
  lose_times: number,
  lose_users: number,
  quit_times: number,
  quit_users: number
}

const { getStage_GroupByPeriod } = daymareZeroApi;
const { convertText } = numberHelper;

const ItemToCell = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiCoinStack className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};



const DaymareStage_GroupByPeriod = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: stageData, isLoading: stageLoading } = useQuery(
    ['stage-by-period', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getStage_GroupByPeriod({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IStage[];
    },
  );

  const tableData = useMemo(() => {
    return stageData?.map((item) => {
      return [
        {
          value: item.period,
          users: null,
          usersRaw: null,
        },
        {
          value: item.start_times,
          users: `${convertText(item.start_users)} (${((item.start_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.start_users,
        },
        {
          value: item.win_times,
          users: `${convertText(item.win_users)} (${((item.win_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.win_users,
        },
        {
          value: item.lose_times,
          users: `${convertText(item.lose_users)} (${((item.lose_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.lose_users,
        },
        {
          value: item.quit_times,
          users: `${convertText(item.quit_users)} (${((item.quit_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.quit_users,
        },
      ];
    });
  }, [stageData]);

  const isLoading = stageLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Steps"
              headers={[
                'Time',
                'Start',
                'Win',
                'Lose',
                'Quit',
                ]}
                data={tableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ItemToCell users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={stageData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareStage_GroupByPeriod;
