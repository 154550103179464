import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiTimer, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import numberHelper from 'config/helpers/numberHelper';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IOpenChest {
  time: string,
  daily_users: number,
  hero_x1_gem_times: number,
  hero_x1_gem_users: number,
  hero_x1_key_times: number,
  hero_x1_key_users: number,
  hero_x10_gem_times: number,
  hero_x10_gem_users: number,
  hero_x10_key_times: number,
  hero_x10_key_users: number,
  equip_x1_gem_times: number,
  equip_x1_gem_users: number,
  equip_x1_key_times: number,
  equip_x1_key_users: number,
  equip_x10_gem_times: number,
  equip_x10_gem_users: number,
  equip_x10_key_times: number,
  equip_x10_key_users: number
}

const { getGacha } = daymareZeroApi;
const { convertText } = numberHelper;

const ItemToCell = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiTimer className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};


const DaymareOpenChest = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['gacha', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeDate?.start || !filter.rangeDate?.end) {
        return [];
      }
      const res = await getGacha({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate.start,
          end: filter.rangeDate.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IOpenChest[];
    },
  );

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.hero_x1_gem_times,
          users: `${convertText(item.hero_x1_gem_users)} (${((item.hero_x1_gem_users / item.daily_users) * 100).toFixed(1)}%)`
        },
        {
          value: item.hero_x10_gem_times,
          users: `${convertText(item.hero_x10_gem_users)} (${((item.hero_x10_gem_users / item.daily_users) * 100).toFixed(1)}%)`
        },
        {
          value: item.hero_x1_key_times,
          users: `${convertText(item.hero_x1_key_users)} (${((item.hero_x1_key_users / item.daily_users) * 100).toFixed(1)}%)`
        },
        {
          value: item.hero_x10_key_times,
          users: `${convertText(item.hero_x10_key_users)} (${((item.hero_x10_key_users / item.daily_users) * 100).toFixed(1)}%)`
        },
        {
          value: item.equip_x1_gem_times,
          users: `${convertText(item.equip_x1_gem_users)} (${((item.equip_x1_gem_users / item.daily_users) * 100).toFixed(1)}%)`
        },
        {
          value: item.equip_x10_gem_times,
          users: `${convertText(item.equip_x10_gem_users)} (${((item.equip_x10_gem_users / item.daily_users) * 100).toFixed(1)}%)`
        },
        {
          value: item.equip_x1_key_times,
          users: `${convertText(item.equip_x1_key_users)} (${((item.equip_x1_key_users / item.daily_users) * 100).toFixed(1)}%)`
        },
        {
          value: item.equip_x10_key_times,
          users: `${convertText(item.equip_x10_key_users)} (${((item.equip_x10_key_users / item.daily_users) * 100).toFixed(1)}%)`
        },
      ];
    });
  }, [rawData]);

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Last Action"
              headers={[
                'Time',
                'Hero x1 Gem',
                'Hero x10 Gem',
                'Hero x1 Key',
                'Hero x10 Key',
                'Equip x1 Gem',
                'Equip x10 Gem',
                'Equip x1 Key',
                'Equip x10 Key',
                ]}
                data={tableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ItemToCell users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
                rawData={rawData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareOpenChest;
