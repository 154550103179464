import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface GetMimilandReq {
  start?: string;
  end?: string;
  groupBy: 'week' | 'date';
}

export interface GetFirstLaunchReq {
  start?: string;
  end?: string;
}

export interface GetMimilandItemClickReq {
  start?: string;
  end?: string;
  platform?: string;
  version: string | null;
  tab: string;
  items: string[];
}

export interface IBaseQueryBody {
  start?: string;
  end?: string;
  platform?: string;
  version: string | null;
}

const getMimilandLoveData = (body: GetMimilandReq, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/love`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandStepSummary = (body: GetMimilandReq, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/tutorial`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandFirstLaunch = (body: GetFirstLaunchReq, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`first-launch/${game}`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandVersions = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/versions`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandTabItems = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/tab-items`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandItemClick = (body: GetMimilandItemClickReq, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/item-click`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandAvgEmote = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/avg-emote`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandTabImpression = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/tab-impression`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandTeleport = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/teleport`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandCatchCoCoStatusStats = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/catch-coco/status`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandCatchCoCoFailedStats = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/catch-coco/failed`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandStepSummaryFull = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/step-summary`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandXmasCatchElf = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/xmas-catch-elf`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandXmasGift = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/xmas-gift`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandXmasPass = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/xmas-pass`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandXMasSummary = (body: IBaseQueryBody, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`mimiland/xmas-summary`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandGuardianSummary = (query: Omit<IBaseQueryBody, 'version'>, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  const qstring = new URLSearchParams(query).toString();
  if (game) {
    return api.get(`mimiland/guardian-summary?${qstring}`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};
const getMimilandBrandingSummary = (query: Omit<IBaseQueryBody, 'version'>, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  const qstring = new URLSearchParams(query).toString();
  if (game) {
    return api.get(`mimiland/branding?${qstring}`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandOverview = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/step-summary/overview`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandLoveOverview = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/love/overview`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandTetSummary = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/tet-summary`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandTetPass = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/tet-pass`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandDonate = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/donate`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandStory = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/story`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandQuest = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/quest`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandDailyRewardClaim = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/daily-reward-claim`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMimilandDailyRewardImpr = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/daily-reward-impr`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getMinigamesData = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/minigames`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const getResources = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`mimiland/resources-details`, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const mimilandApi = {
  getMimilandLoveData,
  getMimilandStepSummary,
  getMimilandFirstLaunch,
  getMimilandVersions,
  getMimilandTabItems,
  getMimilandItemClick,
  getMimilandAvgEmote,
  getMimilandTabImpression,
  getMimilandTeleport,
  getMimilandCatchCoCoStatusStats,
  getMimilandCatchCoCoFailedStats,
  getMimilandStepSummaryFull,
  getMimilandXmasCatchElf,
  getMimilandXmasGift,
  getMimilandXmasPass,
  getMimilandXMasSummary,
  getMimilandOverview,
  getMimilandLoveOverview,
  getMimilandTetSummary,
  getMimilandTetPass,
  getMimilandDonate,
  getMimilandStory,
  getMimilandQuest,
  getMimilandDailyRewardClaim,
  getMimilandDailyRewardImpr,
  getMimilandGuardianSummary,
  getMimilandBrandingSummary,
  getMinigamesData,
  getResources,
};

export default mimilandApi;
