import React from 'react';
import { CgSoftwareDownload } from 'react-icons/cg';

import useExportExcel from 'hooks/useExportExcel';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';

import { Tooltip } from 'antd';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import { SECONDARY_COLOR } from '../../../config/constants/theme';

interface IMonetizationTableProps {
  columnsData: string[][];
  versionData?: IVersionHistory[];
  labels: { header: string, subHeaders: string[] | null }[];
}

const MonetizationTable = ({ labels, columnsData, versionData }: IMonetizationTableProps) => {
  const exportExcel = useExportExcel({
    data: [labels, ...columnsData],
    fileName: 'seen_monetization',
    type: 'string',
  });

  return (
    <div className="monetization-table max-h-[84vh] w-full overflow-auto">
      <div className="flex justify-between p-2">
        <div></div>
        <CgSoftwareDownload className="text-26 cursor-pointer hover:text-secondary" onClick={exportExcel} />
      </div>
      <table>
        <thead className="sticky top-0 z-10">
          <tr key={uniqueId()}>
            {labels.map((it, i) => (
              <th
                key={uniqueId()}
                className={'table-head-half-height font-medium text-white'}
                style={{
                  backgroundColor: SECONDARY_COLOR,
                }}
                colSpan={it.subHeaders ? it.subHeaders.length : 1}
                rowSpan={it.subHeaders ? 1 : 2}
              >
                <p key={uniqueId()} className={`${i === 0 ? 'mb-0' : 'mt-0'}`}>
                  {it.header}
                </p>
              </th>
            ))}
          </tr>
          <tr key={uniqueId()}>
            {labels.map((it, i) => (
              it.subHeaders && (
                it.subHeaders.map(sit => (
                  <th
                    key={uniqueId()}
                    className={'table-head-half-height font-medium text-white'}
                    style={{
                      backgroundColor: SECONDARY_COLOR,
                    }}
                  >
                    <p key={uniqueId()} className={`${i === 0 ? 'mb-0' : 'mt-0'}`}>
                      {sit}
                    </p>
                  </th>
                ))
              )
            ))}
          </tr>
        </thead>
        <tbody>
          {columnsData.map((item) => (
            <tr key={uniqueId()}>
              {item.map((cell, index) => {
                if (index === 0) {
                  const verData = versionData?.find((version) => version.date === cell);

                  return (
                    <Tooltip
                      title={verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : ''}
                      arrow={false}
                    >
                      <td
                        className={clsx('monetization-table-cell', verData && 'text-primary font-medium')}
                        key={uniqueId()}
                      >
                        {verData ? CALENDAR_TEXT_ICON : ''} {cell}
                      </td>
                    </Tooltip>
                  );
                }

                return (
                  <td className="monetization-table-cell" key={uniqueId()}>
                    {cell}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MonetizationTable;
