import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';
import { ICustomTrackingFilter } from '../../../../config/types/reports';

interface Props {
  filter: ICustomTrackingFilter;
}

interface ITopPU {
  user_id: string,
  user_name: string,
  last_action_date: string,
  amount: number,
}

const { getTopPU } = daymareZeroApi;

const DaymareTopPU = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['top-pu', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true) {
        return [];
      }
      const res = await getTopPU({
        params: {
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as ITopPU[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.user_id },
        { value: item.user_name },
        { value: item.last_action_date },
        { value: item.amount },
      ];
    });
  }, [rawData]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="Top PU"
                headers={[
                  'User ID',
                  'User Name',
                  'Last Action',
                  'Amount',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return subItem.value;
                  });
                })}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default DaymareTopPU;
