import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import DashboardContent from 'components/feature/dashboard/DashboardContent';
import DashboardRetention from 'components/feature/dashboard/DashboardRetention';
import CountrySelect from 'components/shared/CountrySelect';
import CusSelect from 'components/shared/CusSelect';
import FullDatePicker from 'components/shared/FullDatePicker';
import Loading from 'components/shared/Loading';
import Skeleton from 'components/shared/Skeleton';

import firstLaunchApi from 'config/api/bigquery/firstLaunchApi';
import mimilandApi from 'config/api/bigquery/mimilandApi';
import sessionApi, { IDashboardSessionBody } from 'config/api/bigquery/sessionApi';
import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import dateHelper from 'config/helpers/dateHelper';

import { Button, Col, Row } from 'antd';
import DateRangeV2 from '../../components/shared/DateRangeV2';

const { getDashboardSession, getDashboardRetention } = sessionApi;
const { getFirstLaunchData } = firstLaunchApi;
const { getAll } = versionHistoryApi;
const { getMimilandLoveData, getMimilandStepSummary } = mimilandApi;

const { format, getDayBefore } = dateHelper;

export interface IDashboardSessionData {
  period: string;
  DAU: number;
  newUsers: number;
  playtimePerSession: number;
  playtimePerUser: number;
  sessions: number;
  bindingUsers?: number;
}

export interface IDashboardRetentionData {
  date: string;
  value?: number;
  category: string | number;
  users: number;
}

export interface IDashboardRetentionCp {
  date: string;
  value?: number;
  category: string | number;
  users: number;
  type: 'compare';
}

export interface IFirstLaunch {
  time: string;
  version: string;
  data: number;
}

export interface IMimilandLove {
  time: string;
  step: string;
  data: number;
}

export interface IMimilandTutorial {
  time: string;
  avgMess: number;
  avgDistance: number;
}

function isV2(appID?: string): boolean {
  return !!appID && ['DaymareZero'].includes(appID);
}

const Dashboard = () => {
  useDocumentTitle('Dashboard');
  const { currentApp, platform } = LayoutContextConsumer();
  const [selectedCountry, setSelectedCountry] = useState<string>('All');
  const [selectedGroup, setSelectGroup] = useState<'date' | 'week'>('date');
  const [rangeDate, setRangeDate] = useState<IDashboardSessionBody>(
    isV2(currentApp?.id) ? {
      start: format(getDayBefore(new Date(), 8)),
      end: format(getDayBefore(new Date(), 0)),
    } : {
      start: format(getDayBefore(new Date(), 10)),
      end: format(getDayBefore(new Date(), 2)),
    }
  );
  const [isCompare, setIsCompare] = useState<boolean>(false);
  const [compareRange, setCompareRange] = useState<IDashboardSessionBody>({});

  const {
    data: dashboardData,
    isLoading: isSessionLoading,
    isError: isSessionError,
  } = useQuery(
    ['dashboard', rangeDate, currentApp, platform, selectedCountry],
    async () => {
      const response = await getDashboardSession(
        {
          ...rangeDate,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );
      return response as unknown as IDashboardSessionData[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: dashboardCompareData, isFetching: isCompareDashboardLoading } = useQuery(
    ['dashboard-compare', compareRange, currentApp, platform, selectedCountry],
    async () => {
      if (!compareRange.start || !compareRange.end) {
        return [];
      }
      const response = await getDashboardSession(
        {
          ...compareRange,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );
      return response as unknown as IDashboardSessionData[];
    },
    {
      refetchOnWindowFocus: false,
      enabled: isCompare && !!compareRange.start && !!compareRange.end,
      retry: false,
    },
  );

  const {
    data: retentionData,
    isLoading: isRetentionLoading,
    isError: isRetentionError,
  } = useQuery(
    ['retention', rangeDate, currentApp, platform, selectedCountry],
    async () => {
      const response = await getDashboardRetention(
        {
          ...rangeDate,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IDashboardRetentionData[];
    },
    {
      refetchOnWindowFocus: false,
    },
    );

  // get compare data retention
  const { data: retentionCompareData, isFetching: isRetentionCompareLoading } = useQuery(
    ['retention', compareRange, currentApp, platform, selectedCountry],
    async () => {
      const response = await getDashboardRetention(
        {
          ...compareRange,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IDashboardRetentionCp[];
    },
    {
      refetchOnWindowFocus: false,
      enabled: isCompare && !!compareRange.start && !!compareRange.end,
      retry: false,
    },
  );

  const { data: firstLaunchData } = useQuery(
    ['first-launch', currentApp, platform, rangeDate],
    async () => {
      if (rangeDate) {
        const response = await getFirstLaunchData(
          {
            start: rangeDate.start,
            end: rangeDate.end,
          },
          {
            params: {
              platform: platform && platform !== 'unified' ? platform : undefined,
            },
          },
        );

        return response as unknown as IFirstLaunch[];
      }
      return [];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  // get compare data first launch
  const { data: firstLaunchCompareData } = useQuery(
    ['first-launch', currentApp, platform, compareRange],
    async () => {
      if (compareRange) {
        const response = await getFirstLaunchData(
          {
            start: compareRange.start,
            end: compareRange.end,
          },
          {
            params: {
              platform: platform && platform !== 'unified' ? platform : undefined,
            },
          },
        );

        const data = response as unknown as IFirstLaunch[];
        if (!data) return [];

        return data.map((item) => ({
          ...item,
          version: 'C-' + item.version,
        }));
      }
      return [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: isCompare && !!compareRange.start && !!compareRange.end,
      retry: false,
    },
  );

  const { data: versionHistoryData } = useQuery(
    ['version-history', currentApp, platform, rangeDate],
    async () => {
      const response = await getAll({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          startDate: rangeDate.start,
          endDate: rangeDate.end,
        },
      });
      return response as unknown as IVersionHistory[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: mimilandLove } = useQuery(
    ['mimiland-love', currentApp, platform, rangeDate, selectedGroup],
    async () => {
      const response = await getMimilandLoveData(
        {
          start: rangeDate.start,
          end: rangeDate.end,
          groupBy: selectedGroup,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IMimilandLove[];
    },
    {
      refetchOnWindowFocus: false,
      enabled: currentApp?.id === 'Mimiland',
    },
  );

  // get compare data mimiland love
  const { data: mimilandLoveCompareData } = useQuery(
    ['mimiland-love', currentApp, platform, compareRange, selectedGroup],
    async () => {
      const response = await getMimilandLoveData(
        {
          start: compareRange.start,
          end: compareRange.end,
          groupBy: selectedGroup,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IMimilandLove[];
    },
    {
      refetchOnWindowFocus: false,
      enabled: currentApp?.id === 'Mimiland' && isCompare && !!compareRange.start && !!compareRange.end,
    },
  );

  const { data: mimilandTutorial } = useQuery(
    ['mimiland-tutorial', currentApp, platform, rangeDate, selectedGroup],
    async () => {
      const response = await getMimilandStepSummary(
        {
          start: rangeDate.start,
          end: rangeDate.end,
          groupBy: selectedGroup,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IMimilandTutorial[];
    },
    {
      refetchOnWindowFocus: false,
      enabled: currentApp?.id === 'Mimiland',
    },
  );

  const { data: mimilandTutorialCompareData } = useQuery(
    ['mimiland-tutorial', currentApp, platform, compareRange, selectedGroup],
    async () => {
      const response = await getMimilandStepSummary(
        {
          start: compareRange.start,
          end: compareRange.end,
          groupBy: selectedGroup,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IMimilandTutorial[];
    },
    {
      refetchOnWindowFocus: false,
      enabled: currentApp?.id === 'Mimiland' && isCompare && !!compareRange.start && !!compareRange.end,
    },
  );

  const isCompareLoading = useMemo(() => {
    return isCompareDashboardLoading || isRetentionCompareLoading;
  }, [isCompareDashboardLoading, isRetentionCompareLoading]);

  return (
    <div>
      <section className="mb-12">
        <h3 className="page-section-title">Engagement</h3>
        <p className="page-section-subtitle">
          Metrics related to user engagement via retention, new/returning users and playtime
        </p>

        <div className="mb-4 flex flex-wrap gap-6 ">
          {isV2(currentApp?.id)
            ? (<DateRangeV2 rangeDate={rangeDate} setRangeDate={setRangeDate} title={'Select Date'} />)
            : (<FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />)
          }
          <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
          {currentApp?.id === 'Mimiland' && (
            <div className="">
              <h6 className="mb-2 text-16 font-[500]">Group data by</h6>
              <CusSelect
                value={selectedGroup}
                options={[
                  { value: 'date', label: 'Daily' },
                  { value: 'week', label: 'Weekly' },
                ]}
                style={{
                  minWidth: '200px',
                }}
                onChange={(selected) => {
                  setSelectGroup(selected);
                }}
              />
            </div>
          )}
        </div>
        <div className="mb-4 flex flex-wrap items-end gap-6">
          <div className="flex items-center gap-2">
            <FullDatePicker rangeDate={compareRange} setRangeDate={setCompareRange} title="Compare with" />
          </div>
          <Button
            type={isCompare ? 'default' : 'primary'}
            size="small"
            onClick={() => {
              if (isCompare) {
                setCompareRange({});
              }
              setIsCompare(!isCompare);
            }}
            disabled={!compareRange.start || !compareRange.end}
          >
            {isCompare ? 'Cancel' : 'Compare'}
          </Button>
          {isCompareLoading && <Loading />}
        </div>
        <div className="mb-5 flex justify-between">
          <h6 className="text-18 font-semibold">Retention</h6>
        </div>
        {isRetentionLoading || isRetentionError ? (
          <div className="w-full overflow-hidden rounded-[10px]">
            <Skeleton height={400} />
          </div>
        ) : (
          <>
            {retentionData && (
              <DashboardRetention
                data={retentionData}
                compareData={
                  isCompare
                    ? retentionCompareData?.map((i) => ({
                        ...i,
                        type: 'compare',
                      })) || []
                    : []
                }
                versionData={versionHistoryData ? versionHistoryData : []}
              />
            )}
          </>
        )}
      </section>

      <section>
        {isSessionLoading || isSessionError ? (
          <Row gutter={[28, 40]}>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
          </Row>
        ) : (
          dashboardData && (
            <DashboardContent
              isCompare={isCompare}
              dashboardData={dashboardData}
              firstLaunchData={firstLaunchData}
              versionData={versionHistoryData ? versionHistoryData : []}
              mimilandLove={mimilandLove}
              mimilandTutorial={mimilandTutorial}
              compareData={
                isCompare
                  ? {
                      dashboardData: dashboardCompareData,
                      firstLaunchData: firstLaunchCompareData,
                      mimilandLove: mimilandLoveCompareData,
                      mimilandTutorial: mimilandTutorialCompareData,
                    }
                  : undefined
              }
            />
          )
        )}
      </section>
    </div>
  );
};

export default Dashboard;
