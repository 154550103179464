import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Pager from '../../../shared/Pager';
import Loading from 'components/shared/Loading';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { toExcelFileName, writeExcelFile } from '../../../../config/constants/general';
import { ICustomTrackingFilter } from '../../../../config/types/reports';
import { itemToCell_rawStr, itemToCell_rawStr_2lineWrap } from '../../../layout/protected/Custom';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IUserProfile {
  user_pseudo_id: string,
  user_id: string,
  user_name: string,
  email: string,
  ad_id: string,
  fcm_id: string,
  login_method: string,
  created_time: number,
  created_date: string,
  last_action_time: number,
  last_action_date: string,
  country: string,
  platform: string,
  device_model: string,
  user_level: number,
  day_played: number,
  launch_count: number,
  topup_sum: number,
  topup_times: number,
  highest_wave: number,
  combat_point: number,
  highest_process: number,
  curr_claim_process: number,
  is_banned: boolean,
}

interface IUserProfile_Counter {
  total_items: number,
}

const { getUserProfile, countUserProfile } = daymareZeroApi;

const DaymareUserProfile = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();
  const [userProfilePage, setUserProfilePage] = useState<number>(1);

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    [
      'user-profile',
      platform,
      filter.rangeDate?.start, filter.rangeDate?.end,
      filter.rangeDate2?.start, filter.rangeDate2?.end,
      filter.country,
      filter.userID,
      filter.userName,
      filter.paying,
      userProfilePage
    ],
    async () => {
      if (!filter || filter.isFirstLoad === true) {
        return [];
      }
      const res = await getUserProfile({
        params: {
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: filter.country !== 'All' ? filter.country : undefined,
          page: userProfilePage,
          pageSize: 10,
        },
        data: {
          userID: filter.userID,
          userName: filter.userName,
          paying: filter.paying !== 'All' ? filter.paying : undefined,
          lastTimeFrom: filter.rangeDate2?.start,
          lastTimeTo: filter.rangeDate2?.end,
        }
      });
      return res as unknown as IUserProfile[];
    }
  );

  const { data: rawDataCounter, isLoading: rawDataCounterLoading } = useQuery(
    [
      'user-profile-counter',
      platform,
      filter.rangeDate?.start, filter.rangeDate?.end,
      filter.rangeDate2?.start, filter.rangeDate2?.end,
      filter.country,
      filter.userID,
      filter.userName,
      filter.paying,
    ],
    async () => {
      if (!filter || filter.isFirstLoad === true) {
        return [];
      }
      const res = await countUserProfile({
        params: {
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
        data: {
          userID: filter.userID,
          userName: filter.userName,
          paying: filter.paying !== 'All' ? filter.paying : undefined,
          lastTimeFrom: filter.rangeDate2?.start,
          lastTimeTo: filter.rangeDate2?.end,
        }
      });
      return res as unknown as IUserProfile_Counter[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.user_id },
        { value: item.user_name },
        { value: item.email },
        { value: item.login_method },
        { value: item.created_date },
        { value: item.last_action_date },
        { value: item.country },
        { value: item.platform },
        { value: item.device_model },
        { value: item.user_level ? item.user_level.toLocaleString() : '' },
        { value: item.day_played ? item.day_played.toLocaleString() : '' },
        { value: item.launch_count ? item.launch_count.toLocaleString() : '' },
        { value: item.topup_sum ? item.topup_sum.toLocaleString() : '' },
        { value: item.topup_times ? item.topup_times.toLocaleString() : '' },
        { value: item.highest_wave ? item.highest_wave.toLocaleString() : '' },
        { value: item.combat_point ? item.combat_point.toLocaleString() : '' },
        { value: item.highest_process ? item.highest_process.toLocaleString() : '' },
        { value: item.curr_claim_process ? item.curr_claim_process.toLocaleString() : '' },
        { value: item.ad_id },
        { value: item.fcm_id ?? '' },
      ];
    });
  }, [rawData]);

  const guiFormOfCounterData = useMemo(() => {
    if (rawDataCounter !== undefined && rawDataCounter.length > 0) {
      return rawDataCounter[0].total_items;
    }
    return 1;
  }, [rawDataCounter]);

  const onDownload = async () => {
    if (filter) {
      const res = await getUserProfile({
        params: {
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
        data: {
          userID: filter.userID,
          userName: filter.userName,
          paying: filter.paying !== 'All' ? filter.paying : undefined,
          lastTimeFrom: filter.rangeDate2?.start,
          lastTimeTo: filter.rangeDate2?.end,
        },
      });
      writeExcelFile(res, toExcelFileName('User Profile'), 'json');
    }
  };

  const isLoading = rawDataLoading || rawDataCounterLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="User Profile"
                headers={[
                  'User ID',
                  'User Name',
                  'Email',
                  'Login Method',
                  'Created At',
                  'Last Action At',
                  'Country',
                  'Platform',
                  'Device Model',
                  'User Level',
                  'Day Played',
                  'Launch Count',
                  'Topup Sum',
                  'Topup Times',
                  'Highest Wave',
                  'Combat Point',
                  'Highest Process',
                  'Claimed Process',
                  'Ad ID',
                  'FCM ID',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    if (!!subItem.value && subItem.value.length > 100)
                      return itemToCell_rawStr_2lineWrap({ value: subItem.value });
                    else
                      return itemToCell_rawStr({ value: subItem.value });
                  });
                })}
                onDownload={onDownload}
              />
            )
          }
          <Pager
            page={userProfilePage}
            totalPage={Math.floor((guiFormOfCounterData + 9) / 10)}
            setPage={setUserProfilePage}
          />
        </div>
      )}
    </div>
  );
};

export default DaymareUserProfile;
