import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import RawDataTable2 from '../charts/RawDataTable2';
import {
  SECONDARY_COLOR,
  TABLE_COL_1,
  TABLE_COL_2,
  TABLE_COL_3,
  TABLE_COL_4,
  TABLE_COL_5,
  TABLE_COL_6,
  TABLE_COL_7,
  TABLE_COL_8,
} from '../../../config/constants/theme';
import Loading from '../../shared/Loading';
import { IReportFilter } from '../../../config/types/reports';
import { itemToCell_icon, itemToCell_rawStr, itemToCell_rows } from '../../layout/protected/Custom';

interface Props {
  filter: IReportFilter;
}

interface IMonthLtv {
  period: string,
  installs: number | null,
  cost: number | null,
  cpm: number | null,
  cpi: number | null,
  nru: number | null,
  ltv01_iap_revenue: number | null, ltv01_iap_users: number | null, ltv01_ad_revenue: number | null, ltv01_ad_users: number | null,
  total_ltv01: number | null, ltv01_per_user: number | null, ret_01: number | null,
  ltv02_iap_revenue: number | null, ltv02_iap_users: number | null, ltv02_ad_revenue: number | null, ltv02_ad_users: number | null,
  total_ltv02: number | null, ltv02_per_user: number | null, ret_02: number | null,
  ltv03_iap_revenue: number | null, ltv03_iap_users: number | null, ltv03_ad_revenue: number | null, ltv03_ad_users: number | null,
  total_ltv03: number | null, ltv03_per_user: number | null, ret_03: number | null,
  ltv06_iap_revenue: number | null, ltv06_iap_users: number | null, ltv06_ad_revenue: number | null, ltv06_ad_users: number | null,
  total_ltv06: number | null, ltv06_per_user: number | null, ret_06: number | null,
  ltv09_iap_revenue: number | null, ltv09_iap_users: number | null, ltv09_ad_revenue: number | null, ltv09_ad_users: number | null,
  total_ltv09: number | null, ltv09_per_user: number | null, ret_09: number | null,
  ltv12_iap_revenue: number | null, ltv12_iap_users: number | null, ltv12_ad_revenue: number | null, ltv12_ad_users: number | null,
  total_ltv12: number | null, ltv12_per_user: number | null, ret_12: number | null,
}

const { ltvMonth } = daymareZeroApi;

const MonthLTV = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['ltv-month', filter.rangeMonth?.start, filter.rangeMonth?.end, filter.country, ...filter.monthLtvPeriods],
    async () => {
      if (!filter || filter.isFirstLoad === true || !filter.rangeMonth?.start || !filter.rangeMonth?.end) {
        return [];
      }
      const res = await ltvMonth({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeMonth?.start,
          end: filter.rangeMonth?.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
        data: {
          periods: filter.monthLtvPeriods,
        }
      });
      return res as unknown as IMonthLtv[];
    },
  );

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      const fields = [
        [{ icon: '', value: item.period }],
        [{ icon: 'users', value: item.nru ? item.nru.toString() : '' }],
        [{ icon: '', value: item.installs ? item.installs.toString() : '' }],
        [{ icon: '', value: item.cost ? item.cost.toFixed(2).toString() : '' }],
        [{ icon: '', value: item.cpm ? item.cpm.toFixed(2).toString() : '' }],
        [{ icon: '', value: item.cost && item.nru ? (item.cost / item.nru).toFixed(2).toString() : '' }],
        [{ icon: '', value: item.cost && item.installs ? (item.cost / item.installs).toFixed(2).toString() : '' }],
      ];
      if (filter.monthLtvPeriods.includes(1)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv01_iap_revenue ? item.ltv01_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv01_iap_users ? item.ltv01_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv01_ad_revenue ? item.ltv01_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv01_ad_users ? item.ltv01_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv01 ? item.total_ltv01.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv01_per_user ? item.ltv01_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_01 ? `${item.ret_01}` : ''  },
            { icon: 'percent', value: item.ret_01 && item.nru ? `${(item.ret_01 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.monthLtvPeriods.includes(2)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv02_iap_revenue ? item.ltv02_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv02_iap_users ? item.ltv02_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv02_ad_revenue ? item.ltv02_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv02_ad_users ? item.ltv02_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv02 ? item.total_ltv02.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv02_per_user ? item.ltv02_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_02 ? `${item.ret_02}` : '', },
            { icon: 'percent', value: item.ret_02 && item.nru ? `${(item.ret_02 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.monthLtvPeriods.includes(3)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv03_iap_revenue ? item.ltv03_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv03_iap_users ? item.ltv03_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv03_ad_revenue ? item.ltv03_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv03_ad_users ? item.ltv03_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv03 ? item.total_ltv03.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv03_per_user ? item.ltv03_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_03 ? `${item.ret_03}` : '',  },
            { icon: 'percent', value: item.ret_03 && item.nru ? `${(item.ret_03 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.monthLtvPeriods.includes(6)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv06_iap_revenue ? item.ltv06_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv06_iap_users ? item.ltv06_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv06_ad_revenue ? item.ltv06_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv06_ad_users ? item.ltv06_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv06 ? item.total_ltv06.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv06_per_user ? item.ltv06_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_06 ? `${item.ret_06}` : '', },
            { icon: 'percent', value: item.ret_06 && item.nru ? `${(item.ret_06 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.monthLtvPeriods.includes(9)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv09_iap_revenue ? item.ltv09_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv09_iap_users ? item.ltv09_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv09_ad_revenue ? item.ltv09_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv09_ad_users ? item.ltv09_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv09 ? item.total_ltv09.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv09_per_user ? item.ltv09_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_09 ? `${item.ret_09}` : '', },
            { icon: 'percent', value: item.ret_09 && item.nru ? `${(item.ret_09 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      if (filter.monthLtvPeriods.includes(12)) {
        fields.push(...[
          [
            { icon: 'coins', value: item.ltv12_iap_revenue ? item.ltv12_iap_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv12_iap_users ? item.ltv12_iap_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv12_ad_revenue ? item.ltv12_ad_revenue.toFixed(2).toString() : '' },
            { icon: 'users', value: item.ltv12_ad_users ? item.ltv12_ad_users.toString() : '' }
          ],
          [
            { icon: 'coins', value: item.total_ltv12 ? item.total_ltv12.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'coins', value: item.ltv12_per_user ? item.ltv12_per_user.toFixed(2).toString() : '' }
          ],
          [
            { icon: 'users', value: item.ret_12 ? `${item.ret_12}` : '', },
            { icon: 'percent', value: item.ret_12 && item.nru ? `${(item.ret_12 * 100 / item.nru).toFixed(2).toString()}` : '' }
          ],
        ]);
      }
      return fields;
    });
  }, [filter.monthLtvPeriods, rawData]);

  const getHeaders = () => {
    const headers: { header: string, subHeaders: string[] | null }[] = [
      { header: 'Period', subHeaders: null },
      { header: 'NRU', subHeaders: null },
      { header: 'Installs', subHeaders: null },
      { header: 'Cost ($)', subHeaders: null },
      { header: 'CPM ($)', subHeaders: null },
      { header: 'CPN ($)', subHeaders: null },
      { header: 'CPI ($)', subHeaders: null },
    ];
    if (filter.monthLtvPeriods.includes(1)) {
      headers.push({ header: '1-Month', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.monthLtvPeriods.includes(2)) {
      headers.push({ header: '2-Month', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.monthLtvPeriods.includes(3)) {
      headers.push({ header: '3-Month', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.monthLtvPeriods.includes(6)) {
      headers.push({ header: '6-Month', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.monthLtvPeriods.includes(9)) {
      headers.push({ header: '9-Month', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    if (filter.monthLtvPeriods.includes(12)) {
      headers.push({ header: '12-Month', subHeaders: ['IAP', 'Ads', 'Total', 'LTV', 'Cohort'] });
    }
    return headers;
  };

  const getColors = () => {
    const colors = [
      SECONDARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR,
      SECONDARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR
    ];
    if (filter.monthLtvPeriods.includes(1)) {
      colors.push(...[TABLE_COL_1]);
    }
    if (filter.monthLtvPeriods.includes(2)) {
      colors.push(...[TABLE_COL_2]);
    }
    if (filter.monthLtvPeriods.includes(3)) {
      colors.push(...[TABLE_COL_3]);
    }
    if (filter.monthLtvPeriods.includes(6)) {
      colors.push(...[TABLE_COL_4]);
    }
    if (filter.monthLtvPeriods.includes(9)) {
      colors.push(...[TABLE_COL_5]);
    }
    if (filter.monthLtvPeriods.includes(12)) {
      colors.push(...[TABLE_COL_6]);
    }
    return colors;
  };

  const isLoading = dataLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
              <RawDataTable2
                isCenterFull
                title="Month LTV"
                colors={getColors()}
                headers={getHeaders()}
                data={tableData.map((item) => {
                  return item.map((subItem) => {
                    if (subItem.length === 1) {
                      if (subItem[0].value === '') return (<></>);
                      else if (subItem[0].icon === null) return itemToCell_rawStr({ value: subItem[0].value });
                      else return itemToCell_icon({ value: subItem[0].value, icon: subItem[0].icon });
                    } else {
                      return itemToCell_rows({ rows: subItem });
                    }
                  });
                })}
                rawData={rawData?.map(item => {
                  return {
                    period: item.period,
                    nru: item.nru,
                    installs: item.installs,
                    cost: item.cost,
                    cpm: item.cpm,
                    cpn: item.cost && item.nru ? Number((item.cost / item.nru).toFixed(2)) : undefined,
                    cpi: item.cost && item.installs ? Number((item.cost / item.installs).toFixed(2)) : undefined,
                    ...filter.monthLtvPeriods.includes(1) && {
                      iap_rev_1m: item.ltv01_iap_revenue ? Number(item.ltv01_iap_revenue.toFixed(2)) : undefined,
                      iap_users_1m: item.ltv01_iap_users,
                      ad_rev_1m: item.ltv01_ad_revenue ? Number(item.ltv01_ad_revenue.toFixed(2)) : undefined,
                      ad_users_1m: item.ltv01_ad_users,
                      total_rev_1m: item.total_ltv01 ? Number(item.total_ltv01.toFixed(2)) : undefined,
                      ltv_1m: item.ltv01_per_user,
                      retention_1m: item.ret_01,
                      'retention_%_1m': item.ret_01 && item.nru ? Number((item.ret_01 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.monthLtvPeriods.includes(2) && {
                      iap_rev_2m: item.ltv02_iap_revenue ? Number(item.ltv02_iap_revenue.toFixed(2)) : undefined,
                      iap_users_2m: item.ltv02_iap_users,
                      ad_rev_2m: item.ltv02_ad_revenue ? Number(item.ltv02_ad_revenue.toFixed(2)) : undefined,
                      ad_users_2m: item.ltv02_ad_users,
                      total_rev_2m: item.total_ltv02 ? Number(item.total_ltv02.toFixed(2)) : undefined,
                      ltv_2m: item.ltv02_per_user,
                      retention_2m: item.ret_02,
                      'retention_%_2m': item.ret_02 && item.nru ? Number((item.ret_02 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.monthLtvPeriods.includes(3) && {
                      iap_rev_3m: item.ltv03_iap_revenue ? Number(item.ltv03_iap_revenue.toFixed(2)) : undefined,
                      iap_users_3m: item.ltv03_iap_users,
                      ad_rev_3m: item.ltv03_ad_revenue ? Number(item.ltv03_ad_revenue.toFixed(2)) : undefined,
                      ad_users_3m: item.ltv03_ad_users,
                      total_rev_3m: item.total_ltv03 ? Number(item.total_ltv03.toFixed(2)) : undefined,
                      ltv_3m: item.ltv03_per_user,
                      retention_3m: item.ret_03,
                      'retention_%_3m': item.ret_03 && item.nru ? Number((item.ret_03 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.monthLtvPeriods.includes(6) && {
                      iap_rev_6m: item.ltv06_iap_revenue ? Number(item.ltv06_iap_revenue.toFixed(2)) : undefined,
                      iap_users_6m: item.ltv06_iap_users,
                      ad_rev_6m: item.ltv06_ad_revenue ? Number(item.ltv06_ad_revenue.toFixed(2)) : undefined,
                      ad_users_6m: item.ltv06_ad_users,
                      total_rev_6m: item.total_ltv06 ? Number(item.total_ltv06.toFixed(2)) : undefined,
                      ltv_6m: item.ltv06_per_user,
                      retention_6m: item.ret_06,
                      'retention_%_6m': item.ret_06 && item.nru ? Number((item.ret_06 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.monthLtvPeriods.includes(9) && {
                      iap_rev_9m: item.ltv09_iap_revenue ? Number(item.ltv09_iap_revenue.toFixed(2)) : undefined,
                      iap_users_9m: item.ltv09_iap_users,
                      ad_rev_9m: item.ltv09_ad_revenue ? Number(item.ltv09_ad_revenue.toFixed(2)) : undefined,
                      ad_users_9m: item.ltv09_ad_users,
                      total_rev_9m: item.total_ltv09 ? Number(item.total_ltv09.toFixed(2)) : undefined,
                      ltv_9m: item.ltv09_per_user,
                      retention_9m: item.ret_09,
                      'retention_%_9m': item.ret_09 && item.nru ? Number((item.ret_09 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                    ...filter.monthLtvPeriods.includes(12) && {
                      iap_rev_12m: item.ltv12_iap_revenue ? Number(item.ltv12_iap_revenue.toFixed(2)) : undefined,
                      iap_users_12m: item.ltv12_iap_users,
                      ad_rev_12m: item.ltv12_ad_revenue ? Number(item.ltv12_ad_revenue.toFixed(2)) : undefined,
                      ad_users_12m: item.ltv12_ad_users,
                      total_rev_12m: item.total_ltv12 ? Number(item.total_ltv12.toFixed(2)) : undefined,
                      ltv_12m: item.ltv12_per_user,
                      retention_12m: item.ret_12,
                      'retention_%_12m': item.ret_12 && item.nru ? Number((item.ret_12 * 100 / item.nru).toFixed(2)) : undefined,
                    },
                  };
                })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MonthLTV;
