import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import Pager from '../../../shared/Pager';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { toExcelFileName, writeExcelFile } from '../../../../config/constants/general';
import { ICustomTrackingFilter } from '../../../../config/types/reports';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';

interface Props {
  filter: ICustomTrackingFilter;
}

interface IAwakenData {
  user_id: string,
  user_name: string,
  user_level: number,
  period: string,
  id: string,
  rarity: string,
  type: string,
}

interface IAwakenData_Counter {
  total_items: number,
}

const { getAwakenData, countAwakenData } = daymareZeroApi;

const DaymareIAPSuccessRawData = ({ filter }: Props) => {
  const { platform } = LayoutContextConsumer();
  const [successIAPPage, setSuccessIAPPage] = useState<number>(1);

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['awaken', platform, successIAPPage, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true) {
        return [];
      }
      const res = await getAwakenData({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          country: filter.country !== 'All' ? filter.country : undefined,
          page: successIAPPage,
          pageSize: 10,
        },
      });
      return res as unknown as IAwakenData[];
    }
  );

  const { data: rawDataCounter, isLoading: rawDataCounterLoading } = useQuery(
    ['awaken-counter', platform, filter.rangeDate?.start, filter.rangeDate?.end, filter.country],
    async () => {
      if (!filter || filter.isFirstLoad === true) {
        return [];
      }
      const res = await countAwakenData({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: filter.rangeDate?.start,
          end: filter.rangeDate?.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as IAwakenData_Counter[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.period },
        { value: item.user_id },
        { value: item.user_name },
        { value: item.user_level.toString() },
        { value: item.id },
        { value: item.rarity },
        { value: item.type },
      ];
    });
  }, [rawData]);

  const guiFormOfCounterData = useMemo(() => {
    if (rawDataCounter !== undefined && rawDataCounter.length > 0) {
      return rawDataCounter[0].total_items;
    }
    return 1;
  }, [rawDataCounter]);

  const onDownload = async () => {
    const res = await getAwakenData({
      params: {
        platform: platform === 'unified' ? undefined : platform,
        start: filter.rangeDate?.start,
        end: filter.rangeDate?.end,
        country: filter.country !== 'All' ? filter.country : undefined,
      },
      data: {
        userID: filter.userID,
      }
    });
    writeExcelFile(res, toExcelFileName('Success IAP'), 'json');
  };

  const isLoading = rawDataLoading || rawDataCounterLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex gap-0 flex-wrap">
            {
              guiFormOfRawData && guiFormOfRawData.length > 0 && (
                <RawDataTable
                  isCenterFull
                  title="Success IAP"
                  headers={[
                    'Time',
                    'User ID',
                    'User Name',
                    'User Level',
                    'ID',
                    'Rarity',
                    'Type',
                  ]}
                  data={guiFormOfRawData.map((item) => {
                    return item.map((subItem) => {
                      return itemToCell_rawStr({ value: subItem.value });
                    });
                  })}
                  onDownload={onDownload}
                />
              )
            }
          </div>
          <div className="flex gap-0 flex-wrap">
            <Pager
              page={successIAPPage}
              totalPage={Math.floor((guiFormOfCounterData + 9) / 10)}
              setPage={setSuccessIAPPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DaymareIAPSuccessRawData;
